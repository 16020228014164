import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "@/utils/network";

let logger = newLogger("MyTodoStore");

//TODO FIXME: EZ MOST A CREDIT-ES TODO-kat így nem veszi figyelembe, hosszútávon itt mergelni kéne backenden vagy frontenden!!!
const urls = {
  count: "/credit/my-todo/count",
  actives: "/credit/my-todo/actives",
  previouses: "/credit/my-todo/previouses",
  mortgageCount: "/mortgage/my-todo/count",
  mortgageActives: "/mortgage/my-todo/actives",
  mortgagePreviouses: "/mortgage/my-todo/previouses",
};

const initState = () => {
  return {
    count: 0,
    actives: [],
    previouses: [],
    mortgageCount: 0,
    mortgageActives: [],
    mortgagePreviouses: [],
  };
};

const actions = {
  fetchCount: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.count);
      commit("saveCount", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchActives: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.actives);
      commit("saveActives", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchPreviouses: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.previouses);
      commit("savePreviouses", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMortgageCount: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.mortgageCount);
      commit("saveMortgageCount", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMortgageActives: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.mortgageActives);
      commit("saveMortgageActives", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMortgagePreviouses: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.mortgagePreviouses);
      commit("saveMortgagePreviouses", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  clear: async ({ commit }) => {
    commit("clear");
  },
};

const mutations = {
  saveCount: (state, count) => {
    Vue.set(state, "count", count);
  },
  saveActives: (state, actives) => {
    Vue.set(state, "actives", actives);
  },
  savePreviouses: (state, previouses) => {
    Vue.set(state, "previouses", previouses);
  },
  saveMortgageCount: (state, count) => {
    Vue.set(state, "mortgageCount", count);
  },
  saveMortgageActives: (state, actives) => {
    Vue.set(state, "mortgageActives", actives);
  },
  saveMortgagePreviouses: (state, previouses) => {
    Vue.set(state, "mortgagePreviouses", previouses);
  },
  clear: (state) => {
    Vue.set(state, "count", 0);
  },
};

const getters = {
  getCount: (state) => {
    return state.count;
  },
  getActives: (state) => {
    return state.actives;
  },
  getPreviouses: (state) => {
    return state.previouses;
  },
  getMortgageCount: (state) => {
    return state.mortgageCount;
  },
  getMortgageActives: (state) => {
    return state.mortgageActives;
  },
  getMortgagePreviouses: (state) => {
    return state.mortgagePreviouses;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
