import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "../../../utils/network";

let logger = newLogger("AdministrationPsd2FieldStore");

const urls = {
  psd2FieldWithId: (id) => `/administration/psd2-field/${id}`,
};

/**
 * Administration Psd2Field store
 */

const initState = () => {
  return {
    psd2FieldData: {},
  };
};

const actions = {
  fetchOne: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.psd2FieldWithId(id));
      commit("saveOne", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  setCompletedLocally: ({ commit }, fieldId) => {
    commit("setCompleted", fieldId);
  },
};

const mutations = {
  saveOne: (state, data) => {
    Vue.set(state.psd2FieldData, data.id, data);
  },
  setCompleted: (state, fieldId) => {
    Vue.set(state.psd2FieldData[fieldId], "completed", true);
  },
};

const getters = {
  get: (state) => (id) => {
    return state.psd2FieldData[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
