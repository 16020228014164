<template>
  <div class="inactivity-modal" :class="brand">
    <div class="inactivity-modal__body">
      <div class="inactivity-modal__image"></div>

      <div class="inactivity-modal__text">
        <p class="is-size-4 has-text-weight-bold">
          {{ $t("af:inactivity_modal.title") }}
        </p>

        <div class="mb-6">
          <div class="usp is-flex is-align-items-center my-2">
            <span class="has-text-grey-icon">{{
              $t("af:inactivity_modal.subtitle")
            }}</span>
          </div>

          <div
            v-if="!isNaN(left)"
            class="has-text-weight-700 has-text-align-center"
          >
            {{ secondsToMinutes(left) }}
          </div>
        </div>

        <b-button type="is-green" class="has-text-weight-medium" expanded
          >{{ $t("af:inactivity_modal.extend") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { UI_CONFIGS } from "@/utils/const";
import { mapGetters } from "vuex";
import { secondsToMinutes } from "@/utils/util";

export default {
  name: "InactivityIntentModal",
  computed: {
    UI_CONFIGS: () => UI_CONFIGS,
    ...mapGetters({
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    brand() {
      return this.$store.getters["config/getAutoloadConfigValue"](
        UI_CONFIGS.APPEARANCE
      );
    },
    ...mapGetters({
      left: "session/timer",
    }),
  },
  methods: {
    secondsToMinutes,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.inactivity-modal {
  width: 600px;
  max-width: 80vw;
  background: white;
  border-radius: 24px;
  position: relative;
  margin: auto;

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__image {
    min-height: 30vh;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/images/background-sport-car.png");
    width: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &__text {
    width: 100%;
    padding: 32px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mbh {
  .inactivity-modal__image {
    background-position: 0 0;
    background-size: cover;
    background-image: url("../assets/images/mbh_exit_mobile.png");
  }
}

@media only screen and (min-width: 769px) {
  .inactivity-modal {
    &__body {
      display: flex;
      flex-direction: row;
    }

    &__image {
      border-bottom-left-radius: 24px;
      border-top-right-radius: 0;
      width: 40%;
    }

    &__text {
      padding: 48px !important;
      width: 60%;
    }
  }

  .mbh {
    .inactivity-modal__image {
      background-position: -20px -25px;
      background-size: initial;
      background-image: url("../assets/images/mbh_exit_desktop.png");
    }
  }
}
</style>
