import { newLogger } from "@/utils/util";
import axios from "axios";
import Vue from "vue";

let logger = newLogger("DefaultOfferStore");

const urls = {
  getOffer: (identifier) => `/api/administration/public/offer/${identifier}`,
};

/**
 * This module handles the default selected offer, if applicable
 */

const initState = () => {
  return {
    offer: {},
    offerLoaded: false,
    offerIdentifier: null,
  };
};

const actions = {
  fetchOffer: async ({ commit }, identifier) => {
    try {
      let { data } = await axios.get(urls.getOffer(identifier));
      commit("saveOffer", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveOffer: (state, data) => {
    Vue.set(state, "offer", data);
    Vue.set(state, "offerIdentifier", data.identifier);
    Vue.set(state, "offerLoaded", true);
  },
};

const getters = {
  getOfferData(state) {
    return state.offer;
  },
  defaultOfferLoaded(state) {
    return !!state.offerIdentifier && state.offerLoaded;
  },
  hasDefaultOffer(state) {
    return !!state.offerIdentifier;
  },
  getOfferIdentifier(state) {
    return state.offerIdentifier;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
