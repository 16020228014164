import network from "../../../utils/network";
import Vue from "vue";

const urls = {
  mySync: "/sync/psd2/my-sync",
  start: "/sync/psd2/start",
  continue: (syncId) => `/sync/psd2/continue/${syncId}`,
  mistakeFound: "/sync/psd2/override",
  failed: (syncId) => `/sync/psd2/failed/${syncId}`,
};

const initState = () => {
  return {
    status: null,
  };
};

const actions = {
  getStatus: async ({ commit }, { force, flowId }) => {
    try {
      const axios = await network.connection();
      let urlParams = new URLSearchParams();
      if (force) {
        urlParams.set("force", force);
      }
      if (flowId) {
        urlParams.set("flowId", flowId);
      }
      let url = `${urls.mySync}?${urlParams.toString()}`;
      let { data } = await axios.get(url);
      commit("saveStatus", data);
      return data;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  start: async ({}, request) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.post(urls.start, request);
      return data;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  continue: async ({}, syncId) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.post(urls.continue(syncId));
      return data;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  setFailed: async ({}, syncId) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.patch(urls.failed(syncId));
      return data;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  mistakeFound: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.patch(urls.mistakeFound, request);
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveStatus: (state, data) => {
    Vue.set(state, "status", data);
  },
};

const getters = {
  status: (state) => {
    return state.status;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
