import { newLogger } from "@/utils/util";
import network from "../../../utils/network";
import Vue from "vue";
import eventBus, { EVENTS } from "@/utils/eventBus";
import { LOCAL_STORAGE_KEYS } from "@/utils/const";

let logger = newLogger("ProfileStore");

// const CUSTOMER_STATUS_STORAGE_KEY = "fintrous_instacash_status_storage";

const urls = {
  statementAcceptances: `/statements`,
  dynamicStatementAcceptances: `/dynamic-statements`,
  profile: `/profile`,
  profileUpdate: `/profile/update`,
  statusUpdate: "/profile/status",
  mortgageStatusUpdate: "/profile/mortgage-status",
  passwordChange: `/bulwark/customer/password-change`,
  isEmailConfirmed: "/bulwark/customer/is-email-confirmed",
  sendConfirmationEmail: "/bulwark/customer/auth/send-confirmation-email",
  customerEmailConfirm: "/customer/email-confirm",
  changeEmail: "/bulwark/customer/auth/change-email",
  changePhone: "/bulwark/customer/auth/change-phone",
  confirmPhone: "/bulwark/customer/auth/confirm-phone",
  updateApplicationMode: "/profile/application-mode",
};

/**
 * Customer store
 */

const initState = () => {
  /*
  let storedStatus = localStorage.getItem(CUSTOMER_STATUS_STORAGE_KEY);
  if (storedStatus) {
    if (storedStatus.includes("IN_FLOW")) {
      let states = storedStatus.split("|");
      return {
        profile: {
          status: states[0],
          flowPosition: states[1],
          flowPage: states[2],
        },
        statementAcceptances: [],
      };
    } else {
      return {
        profile: {
          status: storedStatus,
          flowPosition: null,
          flowPage: null,
        },
        statementAcceptances: [],
      };
    }
  } else {
  */

  return {
    profile: {
      status: null,
      flowPosition: null,
      flowPage: null,
      mortgageStatus: null,
      mortgageFlowPosition: null,
      mortgageFlowPage: null,
      currentApplicationMode: null,
    },
    statementAcceptances: [],
    dynamicStatementAcceptances: [],
  };
  //}
};

const actions = {
  fetch: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.profile);
      commit("saveProfile", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  save: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.put(urls.profileUpdate, request);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  passwordChange: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.post(urls.passwordChange, request);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  emailChange: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.post(urls.changeEmail, request);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  phoneChange: async ({}, request) => {
    try {
      const axios = await network.connection();
      const response = await axios.post(urls.changePhone, request);
      return response.data.tokenId;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  confirmPhone: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.post(urls.confirmPhone, request);
    } catch (e) {
      logger.warn(e);
      return Promise.reject(e);
    }
  },
  fetchStatementAcceptances: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.statementAcceptances);
      commit("saveStatementAcceptances", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchDynamicStatementAcceptances: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.dynamicStatementAcceptances);
      commit("saveDynamicStatementAcceptances", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  sendConfirmationEmail: async ({}, nonce) => {
    try {
      const axios = await network.connection();
      await axios.post(urls.sendConfirmationEmail, { nonce: nonce });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  setStatus: async ({ commit }, loginResponse) => {
    // called from Login
    let page;
    switch (loginResponse.status) {
      case "REGISTERED":
        page = "/onboarding";
        break;
      case "BNPL_REGISTERED":
        page = "/onboarding";
        break;
      case "ONBOARDED":
        page = "/preliminary-assessment";
        break;
      case "PRELIMINARY":
        page = "/offers";
        break;
      case "IN_FLOW":
        page = "/application";
        break;
      case "CLOSED":
        page = "/403";
        break;
      default:
        page = "/";
        break;
    }

    const applicationInviteCode = localStorage.getItem(
      LOCAL_STORAGE_KEYS.APPLICATION_INVITE_CODE
    );
    if (applicationInviteCode) {
      page = "/mortgage/invitation-offer";
    }

    commit("saveLoginStatus", loginResponse);

    return page;
  },
  setMortgageStatus: async ({ commit }, loginResponse) => {
    // called from Login
    let page;
    switch (loginResponse.mortgageStatus) {
      case "REGISTERED":
        page = "/mortgage/onboarding";
        break;
      case "BNPL_REGISTERED":
        page = "/mortgage/onboarding";
        break;
      case "ONBOARDED":
        page = "/mortgage/preliminary-assessment";
        break;
      case "PRELIMINARY":
        page = "/mortgage/offers";
        break;
      case "IN_FLOW":
        page = "/mortgage/application";
        break;
      case "CLOSED":
        page = "/403";
        break;
      default:
        page = "/";
        break;
    }
    const applicationInviteCode = localStorage.getItem(
      LOCAL_STORAGE_KEYS.APPLICATION_INVITE_CODE
    );
    if (applicationInviteCode) {
      page = "/mortgage/invitation-offer";
    }

    commit("saveLoginStatus", loginResponse);

    return page;
  },
  updateStatus: async ({ commit }, { status, result }) => {
    try {
      let axios = await network.connection();
      await axios.put(urls.statusUpdate, {
        currentStatus: status,
        currentStatusResult: result,
      });
      commit("saveStatus", status);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  updateMortgageStatus: async ({ commit }, { status, result }) => {
    try {
      let axios = await network.connection();
      await axios.put(urls.mortgageStatusUpdate, {
        currentMortgageStatus: status,
        currentMortgageStatusResult: result,
      });
      commit("saveMortgageStatus", status);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  isEmailConfirmed: async () => {
    try {
      let axios = await network.connection();
      let response = await axios.get(urls.isEmailConfirmed);
      return response.data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  updateCustomerEmailConfirm: async ({}, { offerId }) => {
    try {
      let axios = await network.connection();
      await axios.patch(urls.customerEmailConfirm, {
        offerId: offerId,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  getEmailConfirmationData: async () => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.customerEmailConfirm);
      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  updateApplicationMode: async ({ commit }, newMode) => {
    try {
      let axios = await network.connection();
      await axios.put(urls.updateApplicationMode, newMode);
      commit("saveCurrentApplicationMode", newMode);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveProfile: (state, data) => {
    state.profile = data;
    state.profile.status = data.currentStatus;
    state.profile.statusResult = data.currentStatusResult;
    state.profile.mortgageStatus = data.currentMortgageStatus;
    state.profile.mortgageStatusResult = data.currentMortgageStatusResult;
    state.profile.currentApplicationMode = data.currentApplicationMode;
  },
  saveStatementAcceptances: (state, data) => {
    Vue.set(state, "statementAcceptances", data);
  },
  saveDynamicStatementAcceptances: (state, data) => {
    Vue.set(state, "dynamicStatementAcceptances", data);
  },
  saveLoginStatus: (state, response) => {
    Vue.set(state.profile, "status", response.status);
    Vue.set(state.profile, "flowPosition", response.flowPosition);
    Vue.set(state.profile, "flowPage", response.flowPage);
    Vue.set(state.profile, "mortgageStatus", response.mortgageStatus);
    Vue.set(
      state.profile,
      "mortgageFlowPosition",
      response.mortgageFlowPosition
    );
    Vue.set(state.profile, "mortgageFlowPage", response.mortgageFlowPage);
    //if (response.status.includes("IN_FLOW")) {
    //  localStorage.setItem(
    //    CUSTOMER_STATUS_STORAGE_KEY,
    //    `${response.status}|${response.flowPosition}|${response.flowPage}`
    //  );
    //} else {
    //    localStorage.setItem(CUSTOMER_STATUS_STORAGE_KEY, response.status);
    //}
  },
  saveStatus: (state, status) => {
    Vue.set(state.profile, "status", status);
    // localStorage.setItem(CUSTOMER_STATUS_STORAGE_KEY, status);
    eventBus.$emit(EVENTS.STATUS_UPDATED);
  },
  saveCurrentApplicationMode: (state, mode) => {
    Vue.set(state.profile, "currentApplicationMode", mode);
  },
};

const getters = {
  get: (state) => {
    return state.profile;
  },
  getStatementAcceptances: (state) => {
    return state.statementAcceptances;
  },
  getDynamicStatementAcceptances: (state) => {
    return state.dynamicStatementAcceptances;
  },
  hasActiveApplication: (state) => {
    return state.profile.status === "IN_FLOW";
  },
  hasActiveMortgageApplication: (state) => {
    return state.profile.mortgageStatus === "IN_FLOW";
  },
  isInState: (state) => (desired) => {
    return state.profile.status === desired;
  },
  isInMortgageState: (state) => (desired) => {
    return state.profile.mortgageStatus === desired;
  },
  getCurrentApplicationMode: (state) => {
    return state.profile.currentApplicationMode;
  },
  getBackPath: (state) => {
    switch (state.profile.status) {
      case "REGISTERED":
        return "/onboarding";
      case "BNPL_REGISTERED":
        return "/onboarding";
      case "ONBOARDED":
        return "/preliminary-assessment";
      case "PRELIMINARY":
        return "/offers";
      case "IN_FLOW":
        return "/application";
      default:
        return "/";
    }
  },
  getMortgageBackPath: (state) => {
    switch (state.profile.mortgageStatus) {
      case "REGISTERED":
        return "/mortgage/onboarding";
      case "INVITED":
        return "/mortgage/invitation-offer";
      case "BNPL_REGISTERED":
        return "/mortgage/onboarding";
      case "ONBOARDED":
        return "/mortgage/preliminary-assessment";
      case "PRELIMINARY":
        return "/mortgage/offers";
      case "IN_FLOW":
        return "/mortgage/application";
      default:
        return "/";
    }
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
