import i18n from "@/plugins/i18n";
import eventBus, { EVENTS } from "@/utils/eventBus";

export default {
  created() {
    const { title } = this.$options;
    if (title) {
      this.title = typeof title === "function" ? title.call(this) : title;
      eventBus.$on(EVENTS.TOKENS_LOADED, this.setTitle);
      this.setTitle();
    }
  },
  data() {
    return {
      title: undefined,
    };
  },
  methods: {
    setTitle() {
      document.title = i18n.t(this.title);
    },
  },
};
