import Vue from "vue";
import { newLogger } from "../../../utils/util";
import network from "../../../utils/network";
import axios from "axios";

let logger = newLogger("PurchaseStore");

const urls = {
  public: (publicId) => `/api/credit/purchase/public/${publicId}`,
  myPurchase: "/credit/purchase/my-purchase",
  myPurchases: "/credit/purchase/my-purchases",
  purchase: (id) => `/credit/purchase/${id}`,
  hasActivePurchase: "/credit/purchase/has-active-purchase",
  transferred: "/credit/purchase/transferred",
  link: (publicId) => `/credit/purchase/link/${publicId}`,
  cancel: (id) => `/credit/purchase/cancel/${id}`,
};

const initState = () => {
  return {
    publicPurchases: {},
    myPurchases: {},
    purchase: {},
    activePurchase: null,
    hasActivePurchase: false,
  };
};

const actions = {
  fetchPublic: async ({ commit }, publicId) => {
    try {
      const { data } = await axios.get(urls.public(publicId));
      commit("savePublic", {
        publicId,
        data,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchPurchase: async ({ commit }, id) => {
    try {
      let axios = await network.connection();
      const { data } = await axios.get(urls.purchase(id));
      commit("savePurchase", {
        id,
        data,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchActivePurchase: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.myPurchase);
      commit("saveActivePurchase", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMyPurchases: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.myPurchases);
      commit("saveMyPurchases", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  hasActivePurchase: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.hasActivePurchase);
      commit("saveHasActivePurchase", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  transferred: async ({}) => {
    try {
      let axios = await network.connection();
      await axios.patch(urls.transferred);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  link: async ({}, publicId) => {
    try {
      let axios = await network.connection();
      await axios.patch(urls.link(publicId));
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  cancel: async ({}, id) => {
    try {
      let axios = await network.connection();
      await axios.patch(urls.cancel(id));
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  clear: async ({ commit }) => {
    commit("clear");
  },
};

const mutations = {
  savePublic: (state, { publicId: publicId, data: data }) => {
    Vue.set(state.publicPurchases, publicId, data);
  },
  savePurchase: (state, { id: id, data: data }) => {
    Vue.set(state.purchase, id, data);
  },
  saveMyPurchases: (state, data) => {
    Vue.set(state, "myPurchases", data);
  },
  saveActivePurchase: (state, data) => {
    if (data) {
      Vue.set(state, "activePurchase", data);
    }
  },
  saveHasActivePurchase: (state, data) => {
    Vue.set(state, "hasActivePurchase", data);
    if (!data) {
      Vue.set(state, "activePurchase", null);
    }
  },
  clear: (state) => {
    Vue.set(state, "hasActivePurchase", false);
    Vue.set(state, "activePurchase", null);
  },
};

const getters = {
  getPublic: (state) => (publicId) => {
    return state.publicPurchases[publicId];
  },
  getPurchase: (state) => (id) => {
    return state.purchase[id];
  },
  getMyPurchases: (state) => {
    return state.myPurchases;
  },
  getActivePurchase: (state) => {
    return state.activePurchase;
  },
  hasActivePurchase: (state) => {
    return state.hasActivePurchase;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
