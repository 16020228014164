import Vue from "vue";
import { newLogger } from "../../../utils/util";
import network from "../../../utils/network";

let logger = newLogger("TemplateStore");

const urls = {
  templateWithIdentifier: (id) => `/administration/template-render/${id}`,
};

/**
 * Template render store
 */

const initState = () => {
  return {
    templates: {},
  };
};

const actions = {
  fetchTemplate: async ({ commit }, id) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.templateWithIdentifier(id));
      commit("save", { id: id, text: data.text });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  save: (state, { id, text }) => {
    Vue.set(state.templates, id, text);
  },
};

const getters = {
  get: (state) => (id) => {
    return state.templates[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
