import Vue from "vue";
import VueRouter from "vue-router";
import vuex from "../store";
import { saveLandingParams } from "@/utils/landingParamUtil";
import { LOCAL_STORAGE_KEYS } from "@/utils/const";

Vue.use(VueRouter);

const routes = [
  {
    path: "/registration",
    name: "WelcomeRegister",
    props: true,
    component: () => import("@/views/Welcome.vue"),
    meta: {
      layout: "AppLayoutWelcome",
      protected: false,
      action: "registration",
    },
  },
  {
    path: "/mortgage/registration",
    name: "MortgageWelcomeRegister",
    props: true,
    component: () => import("@/views/Welcome.vue"),
    meta: {
      layout: "AppLayoutWelcome",
      protected: false,
      action: "registration",
      mortgage: true,
    },
  },
  {
    path: "/login",
    name: "WelcomeLogin",
    props: true,
    component: () => import("@/views/Welcome.vue"),
    meta: {
      layout: "AppLayoutWelcome",
      protected: false,
      action: "login",
    },
  },
  {
    path: "/mortgage/login",
    name: "MortgageWelcomeLogin",
    props: true,
    component: () => import("@/views/Welcome.vue"),
    meta: {
      layout: "AppLayoutWelcome",
      protected: false,
      action: "login",
      mortgage: true,
    },
  },
  {
    path: "/registration/email",
    name: "EmailRegistration",
    props: true,
    component: () => import("@/views/Registration.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mode: "email",
    },
  },
  {
    path: "/mortgage/registration/email",
    name: "MortgageEmailRegistration",
    props: true,
    component: () => import("@/views/Registration.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mode: "email",
      mortgage: true,
    },
  },
  {
    path: "/registration/phone",
    name: "PhoneRegistration",
    props: true,
    component: () => import("@/views/Registration.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mode: "phone",
    },
  },
  {
    path: "/mortgage/registration/phone",
    name: "MortgagePhoneRegistration",
    props: true,
    component: () => import("@/views/Registration.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mode: "phone",
      mortgage: true,
    },
  },
  {
    path: "/registration/second-factor",
    name: "RegistrationSecondFactor",
    props: true,
    component: () => import("@/views/RegistrationSecondFactor.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
    },
  },
  {
    path: "/mortgage/registration/second-factor",
    name: "MortgageRegistrationSecondFactor",
    props: true,
    component: () => import("@/views/RegistrationSecondFactor.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mortgage: true,
    },
  },
  {
    path: "/login/second-factor",
    name: "LoginSecondFactor",
    props: true,
    component: () => import("@/views/LoginSecondFactor.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
    },
  },
  {
    path: "/login/email",
    name: "EmailLogin",
    props: true,
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mode: "email",
    },
  },
  {
    path: "/emailConfirmation",
    name: "EmailConfirmation",
    props: true,
    component: () => import("@/views/EmailConfirmation.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
    },
  },
  {
    path: "/login/phone",
    name: "PhoneLogin",
    props: true,
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mode: "phone",
    },
  },
  {
    path: "/login/phone/:userId",
    name: "PhoneLogin",
    props: true,
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
      mode: "phone",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      layout: "AppLayoutRegisterLogin",
      protected: false,
    },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("@/views/Onboarding.vue"),
    meta: {
      layout: "AppLayoutOnboarding",
      protected: true,
    },
  },
  {
    path: "/mortgage/onboarding",
    name: "MortgageOnboarding",
    component: () => import("@/views/MortgageOnboarding.vue"),
    meta: {
      layout: "AppLayoutOnboarding",
      protected: true,
      mortgage: true,
    },
  },
  {
    path: "/preliminary-assessment",
    name: "PreliminaryAssessment",
    component: () => import("@/views/PreliminaryAssessment.vue"),
    meta: {
      layout: "AppLayoutPreliminaryAssessment",
      protected: true,
    },
  },
  {
    path: "/mortgage/preliminary-assessment",
    name: "MortgagePreliminaryAssessment",
    component: () => import("@/views/MortgagePreliminaryAssessment.vue"),
    meta: {
      layout: "AppLayoutPreliminaryAssessment",
      protected: true,
      mortgage: true,
    },
  },
  {
    path: "/offers",
    name: "Offers",
    component: () => import("@/views/Offers.vue"),
    meta: {
      protected: true,
    },
  },
  {
    path: "/mortgage/offers",
    name: "MortgageOffers",
    component: () => import("@/views/MortgageOffers.vue"),
    meta: {
      protected: true,
      mortgage: true,
    },
  },
  {
    path: "/mortgage/invitation-offer",
    name: "MortgageInvitationOffer",
    component: () => import("@/views/MortgageInvitationOffer.vue"),
    meta: {
      protected: true,
      mortgage: true,
    },
  },
  {
    path: "/user/profile",
    alias: "/",
    name: "UserProfile",
    component: () => import("@/views/UserProfile.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/user/todos",
    name: "UserTodos",
    component: () => import("@/views/UserTodos.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/user/submit-missing-documents/:id",
    props: true,
    name: "UserSubmitMissingDocuments",
    component: () => import("@/views/UserSubmitMissingDocuments.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/user/applications",
    name: "UserApplications",
    component: () => import("@/views/UserApplications.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/user/mortgage-applications",
    name: "UserMortgageApplications",
    component: () => import("@/views/UserMortgageApplications.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/store/:storeId",
    props: true,
    name: "Store",
    component: () => import("@/views/Store.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/prepayments",
    name: "Prepayments",
    component: () => import("@/views/Prepayments.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/stores",
    name: "Stores",
    component: () => import("@/views/Stores.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/user/purchase-history",
    name: "UserPurchaseHistory",
    component: () => import("@/views/UserPurchaseHistory.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/user/purchase/:purchaseId",
    props: true,
    name: "Purchase",
    component: () => import("@/views/Purchase.vue"),
    meta: {
      layout: "AppLayoutUser",
      protected: true,
    },
  },
  {
    path: "/application",
    name: "LoanApplication",
    props: true,
    component: () => import("@/views/LoanApplicationFlow.vue"),
    meta: {
      layout: "AppLayoutLoanApplication",
      protected: true,
    },
  },
  {
    path: "/mortgage/application",
    name: "MortgageApplication",
    props: true,
    component: () => import("@/views/MortgageApplicationFlow.vue"),
    meta: {
      layout: "AppLayoutLoanApplication",
      protected: true,
      mortgage: true,
    },
  },
  {
    path: "/checkout/:purchaseId",
    name: "Checkout",
    props: true,
    component: () => import("@/views/Checkout.vue"),
    meta: {
      layout: "AppLayoutCheckout",
      protected: false,
    },
  },
  {
    path: "*",
    name: "WelcomeLogin",
    props: true,
    component: () => import("@/views/Welcome.vue"),
    meta: {
      layout: "AppLayoutWelcome",
      protected: false,
      action: "login",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  console.debug("Routing to " + to.name);
  let isLoggedIn = vuex.getters["session/isReady"];

  if (to.query.applicationInviteCode) {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.APPLICATION_INVITE_CODE,
      String(to.query.applicationInviteCode)
    );
  }

  if (
    !isLoggedIn &&
    (to.path === "/" ||
      to.path === "/registration" ||
      to.path === "/registration/email" ||
      to.path === "/registration/phone" ||
      to.path === "/login" ||
      to.path === "/login/email" ||
      to.path === "/login/phone" ||
      to.path === "/mortgage/login" ||
      to.path === "/mortgage/login/email" ||
      to.path === "/mortgage/login/phone" ||
      to.path === "/mortgage/registration" ||
      to.path === "/mortgage/registration/email" ||
      to.path === "/mortgage/registration/phone")
  ) {
    saveLandingParams(to.query);
  }

  if (!to.meta.protected) {
    if (
      !isLoggedIn ||
      to.name === "EmailConfirmation" ||
      to.name === "MortgageEmailConfirmation" ||
      to.name === "Checkout"
    ) {
      next();
    } else {
      if (to.query.mortgageExternalId) {
        saveLandingParams({ mortgageExternalId: to.query.mortgageExternalId });
      }

      next({ path: "/user/profile" });
    }
  } else {
    if (isLoggedIn) {
      next();
    } else {
      sessionStorage.setItem("INITIAL_PATH", to.path);
      next({ path: "/login" });
    }
  }
});

export default router;
