import { newLogger } from "@/utils/util";
import network from "../../../utils/network";
import Vue from "vue";
import {
  PRELIM_FIXED_STEPS,
  PRELIM_PSD2_FIXED_STEPS,
  PRELIM_TYPE,
} from "@/utils/const";

let logger = newLogger("PreliminaryQuestionsStore");

const urls = {
  questions: (type) => `/administration/preliminary/questions/${type}`,
  assessment: "/credit/preliminary-assessment",
  latestAssessment: "/credit/my-preliminary-assessment",
};

const conditions = {
  company_registered_abroad: { field: "source_of_income", answer: "employee" },
  current_job: { field: "source_of_income", answer: "employee" },
  trial_period: { field: "source_of_income", answer: "employee" },
  notice_period: { field: "source_of_income", answer: "employee" },
  incapacity: { field: "source_of_income", answer: "employee" },
  continuous_employment: { field: "source_of_income", answer: "employee" },
  fixed_term_employment_contract: {
    field: "source_of_income",
    answer: "employee",
  },
  fixed_term_employment_contract_length: {
    field: "fixed_term_employment_contract",
    answer: "true",
  },
  scope_of_activities: { field: "source_of_income", answer: "self_employed" },
  closed_business_years: { field: "source_of_income", answer: "self_employed" },
  disability_pension: { field: "source_of_income", answer: "pensioner" },
  committed_salary: { field: "salary_commitment", answer: "true" },
};

/**
 * Administration PreliminaryConfig store
 */

const initState = () => {
  return {
    currentStepIndex: 0,
    steps: [],
    questions: [],
    data: {},
    observer: null,
  };
};

const actions = {
  fetchSteps: async ({ commit }, type) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.questions(type));
      commit("save", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  addData: ({ commit }, data) => {
    try {
      commit("saveData", data);
    } catch (err) {
      logger.error(err);
    }
  },
  submit: async ({ commit, state }) => {
    try {
      commit("offers/unload", null, { root: true });
      let axios = await network.connection();
      let request = {
        amount: state.data.amount,
        duration: state.data.duration,
        downPayment: state.data.downPayment,
        extraAmount: state.data.extraAmount,
        salary: state.data.income,
        salaryCommitment: state.data.salary_commitment,
        committedSalary: state.data.committed_salary,
        prelimStartedAt: state.data.prelimStartedAt,
        type: state.data[PRELIM_TYPE.KEY],
        dynamicData: JSON.stringify(state.data),
        withPpi: state.data.withPpi,
      };
      let { data } = await axios.post(urls.assessment, request, {
        timeout: 120000,
      });
      commit("offers/saveOffers", data, { root: true });
      return data.isEligible;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchLatest: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.latestAssessment);

      if (data && data.dynamicData) {
        data.dynamicData = JSON.parse(data.dynamicData);

        Object.keys(data.dynamicData).forEach((key) => {
          commit("saveData", {
            question: key,
            answer: data.dynamicData[key],
          });
        });
      }
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  recalculate: async (
    { commit, state },
    { amount, duration, downPayment, extraAmount, withPpi }
  ) => {
    commit("offers/unload", null, { root: true });

    let axios = await network.connection();
    let request = {
      amount,
      duration,
      downPayment,
      extraAmount,
      withPpi,
      salary: state.data.income,
      salaryCommitment: state.data.salary_commitment,
      committedSalary: state.data.committed_salary,
      dynamicData: JSON.stringify(state.data),
    };

    let { data } = await axios.post(urls.assessment, request);
    commit("offers/saveOffers", data, { root: true });
    return data.isEligible;
  },
  // eslint-disable-next-line unused-imports/no-unused-vars
  drop: async ({ state }) => {
    state = initState();
  },
  backToFirstStep: async ({ commit }) => {
    commit("backToFirstStep");
  },
  async validate({ state }) {
    if (state.observer) {
      state.observer.validate();
      let hasScrolled = false;
      for (const error in state.observer.errors) {
        if (!state.observer.fields[error].valid && !hasScrolled) {
          const offsetTop = state.observer.refs[error].$el.offsetTop - 20;
          if (offsetTop) {
            window.scrollTo(0, offsetTop);
            hasScrolled = true;
          }
        }
      }
    }
  },
};

const mutations = {
  save: (state, data) => {
    state.steps = [];
    state.questions = [];
    let t = 0;
    for (let s in data.steps) {
      state.steps.push(data.steps[s]);
      t += data.steps[s].time;
      for (let q in data.steps[s].questions) {
        state.questions.push(data.steps[s].questions[q]);
      }
    }
    Vue.set(state, "time", t);
  },
  saveData: (state, { question, answer }) => {
    Vue.set(state.data, question, answer);
  },
  nextStep: (state) => {
    state.currentStepIndex++;
  },
  previousStep: (state) => {
    state.currentStepIndex--;
  },
  backToFirstStep: (state) => {
    state.currentStepIndex = 0;
  },
  setObserver: (state, observer) => {
    state.observer = observer;
  },
};

const getters = {
  currentStepIndex(state) {
    return state.currentStepIndex;
  },
  isPsd2(state) {
    return state.data[PRELIM_TYPE.KEY] === PRELIM_TYPE.PSD2;
  },
  getType(/* state */) {
    // return state.data[PRELIM_TYPE.KEY];
    return PRELIM_TYPE.MANUAL;
  },
  fixedSteps(state, getters) {
    let steps = Object.keys(PRELIM_FIXED_STEPS).length;
    if (getters.isPsd2) {
      steps += Object.keys(PRELIM_PSD2_FIXED_STEPS).length;
    }
    return steps;
  },
  steps(state) {
    return state.steps;
  },
  step: (state, getters) => (id) => {
    if (!id || id < getters.fixedSteps) {
      return null;
    } else {
      return state.steps[parseInt(id) - getters.fixedSteps];
    }
  },
  maxTime: (state, getters) => {
    return Math.round((state.time ? state.time : 0) / 60) + getters.fixedSteps;
  },
  stepTime: (state, getters) => (id) => {
    if (!id || id < getters.fixedSteps) return getters.fixedSteps - id;
    return state.steps[parseInt(id) - getters.fixedSteps].time / 60;
  },
  answer: (state) => (question) => {
    return state.data[question];
  },
  checkStep: (state, getters) => (id) => {
    if (!id || id === PRELIM_FIXED_STEPS.AMOUNT_AND_TERM) {
      return true;
    }
    if (id === PRELIM_FIXED_STEPS.TYPE_SELECT) {
      return !!state.data[PRELIM_TYPE.KEY];
    }

    if (getters.isPsd2 && id === PRELIM_PSD2_FIXED_STEPS.SYNC) {
      return true;
    }

    id = parseInt(id) - getters.fixedSteps;
    let identifiers = [];
    for (let q in state.steps[id].questions) {
      let identifier = state.steps[id].questions[q].identifier;
      if (
        !Object.keys(conditions).includes(identifier) ||
        state.data[conditions[identifier].field] ===
          conditions[identifier].answer
      ) {
        let question = state.steps[id].questions[q];
        if (question.type === "DATE_PICKER") {
          let value = state.data[identifier];
          if (value && question.validation) {
            let today = new Date();
            let dateValue = new Date(value);
            today.setHours(0, 0, 0, 0);
            dateValue.setHours(0, 0, 0, 0);
            if (question.validation === "PAST" && today < dateValue) {
              return false;
            } else if (question.validation === "FUTURE" && today > dateValue) {
              return false;
            }
          }
        }
        if (question.type === "INPUT") {
          // all prelim input fields are number only
          if (isNaN(state.data[identifier])) {
            // NaN check
            return false;
          }
        }
        identifiers.push(identifier);
      }
    }
    let ans = true;
    for (let i in identifiers) {
      ans = ans && !!state.data[identifiers[i]];
    }
    return ans;
  },
  isVisible: (state) => (identifier) => {
    if (!Object.keys(conditions).includes(identifier)) return true;
    return (
      state.data[conditions[identifier].field] === conditions[identifier].answer
    );
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
