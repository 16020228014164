import Vue from "vue";
import { newLogger } from "@/utils/util";
import axios from "axios";
import network from "../../../utils/network";

let logger = newLogger("AcceptanceRequirementsStore");

const urls = {
  registration: "/api/administration/registration-acceptance", // GET
  mortgageRegistration: "/api/administration/mortgage-registration-acceptance", // GET
  calculation: "/administration/calculation-acceptance", // GET
  mortgageCalculation: "/administration/mortgage-calculation-acceptance", // GET
  accept: "/statement/accept", // POST
};

/**
 * Administration Registration Page Configuration store
 */

const initState = () => {
  return {
    regConfig: [],
    mortgageRegConfig: [],
    calcConfig: [],
    mortgageCalcConfig: [],
  };
};

const actions = {
  fetchRegConfig: async ({ commit }) => {
    try {
      let { data } = await axios.get(urls.registration);
      commit("saveReg", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMortgageRegConfig: async ({ commit }) => {
    try {
      let { data } = await axios.get(urls.mortgageRegistration);
      commit("saveMortgageReg", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchCalcConfig: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.calculation);
      commit("saveCalc", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMortgageCalcConfig: async ({ commit }) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.mortgageCalculation);
      commit("saveMortgageCalc", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  acceptStatement: async (_, request) => {
    try {
      let axios = await network.connection();
      await axios.post(urls.accept, request);
      return true;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveReg: (state, configurations) => {
    Vue.set(state, "regConfig", configurations);
  },
  saveMortgageReg: (state, configurations) => {
    Vue.set(state, "mortgageRegConfig", configurations);
  },
  saveCalc: (state, configurations) => {
    Vue.set(state, "calcConfig", configurations);
  },
  saveMortgageCalc: (state, configurations) => {
    Vue.set(state, "mortgageCalcConfig", configurations);
  },
};

const getters = {
  regConfig: (state) => {
    return state.regConfig;
  },
  mortgageRegConfig: (state) => {
    return state.mortgageRegConfig;
  },
  calcConfig: (state) => {
    return state.calcConfig;
  },
  mortgageCalcConfig: (state) => {
    return state.mortgageCalcConfig;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
