import { newLogger } from "@/utils/util";
import axios from "axios";

let logger = newLogger("ImageStore");

const urls = {
  image: "/api/administration/image", // GET
};

/**
 * Fetch aggregated partner-product-offer image JSON
 * Used for partner logos, images, partner specific colors
 */

const initState = () => {
  return {
    data: {}, // the image object itself, mapped by offerId
  };
};

const actions = {
  fetchImage: async ({ commit }) => {
    try {
      const { data } = await axios.get(urls.image);
      commit("save", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  save: (state, data) => {
    state.data = data;
  },
};

const getters = {
  getImageDetailsForOffer: (state) => (offerId) => {
    return state.data[offerId];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
