import { newLogger } from "@/utils/util";
import axios from "axios";

let logger = newLogger("BulwarkPasswordPolicyStore");

const urls = {
  passwordPolicyCustomer: "/api/bulwark/password-policy/customer",
};

const initState = () => {
  return {
    passwordPolicy: null,
  };
};

const actions = {
  fetch: async ({ commit }) => {
    try {
      const { data: data } = await axios.get(urls.passwordPolicyCustomer);
      commit("save", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  save: (state, data) => {
    state.passwordPolicy = data;
  },
};

const getters = {
  get: (state) => {
    return state.passwordPolicy;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
