import Vue from "vue";
import network from "../../../utils/network";
import axios from "axios";

const urls = {
  getCustomerMetaData: (id) => `/drawer/document/${id}/customer-metadata`,
  getMyDocuments: `/drawer/user-documents`,
  download: (id) => `/file/download/${id}`,
  downloadPublic: (id) => `/api/file/public/${id}`,
};

/**
 * ApplicationFlow Drawer FileStorage
 */

const initState = () => {
  return {
    documentList: [],
    downloads: {}, // downloads can be "cached" since their file ID is unique, they cannot be updated
    customerMetaData: {},
  };
};

const actions = {
  download: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let blob = await axios.get(urls.download(id), { responseType: "blob" });
      commit("saveFile", {
        id: id,
        blob: blob.data,
        type: blob.headers["content-type"],
      });
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMyDocuments: async ({ commit }) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.getMyDocuments);
      commit("saveList", data);
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  downloadPublic: async ({ commit }, id) => {
    try {
      let blob = await axios.get(urls.downloadPublic(id), {
        responseType: "blob",
      });
      commit("saveFile", {
        id: id,
        blob: blob.data,
        type: blob.headers["content-type"],
      });
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  getCustomerMetaData: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      const { data: documentMetadata } = await axios.get(
        urls.getCustomerMetaData(id)
      );
      commit("saveCustomerMetadata", { id: id, data: documentMetadata });
      return documentMetadata;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveCustomerMetadata: (state, { id, data }) => {
    Vue.set(state.customerMetaData, id, data);
  },
  saveFile: (state, { id, blob, type }) => {
    Vue.set(state.downloads, id, new Blob([blob], { type: type }));
  },
  saveList: (state, listData) => {
    state.documentList = listData;
  },
};

const getters = {
  getFile: (state) => (id) => {
    return state.downloads[id];
  },
  getCustomerMetaData: (state) => (id) => {
    return state.customerMetaData[id];
  },
  getList: (state) => {
    return state.documentList;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
