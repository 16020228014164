import Vue from "vue";
import { newLogger } from "@/utils/util";
import axios from "axios";
import network from "../../../utils/network";

let logger = newLogger("CallMeBackStore");

const urls = {
  callMeBackPublic: "/api/call-me-back-public",
  callBeBack: "/call-me-back",
  acceptances: "/api/administration/call-me-back-acceptance",
};

const initState = () => {
  return {
    acceptances: [],
  };
};

const actions = {
  createPublic: async (_, request) => {
    try {
      await axios.post(urls.callMeBackPublic, request);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  create: async (_, request) => {
    try {
      let axios = await network.connection();
      await axios.post(urls.callBeBack, request);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  acceptanceStatements: async ({ commit }) => {
    try {
      let { data } = await axios.get(urls.acceptances);
      commit("saveAcceptances", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveAcceptances: (state, configurations) => {
    Vue.set(state, "acceptances", configurations);
  },
};

const getters = {
  acceptances: (state) => {
    return state.acceptances;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
