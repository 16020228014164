import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "@/utils/network";
import axios from "axios";

let logger = newLogger("OffersStore");

const urls = {
  offerDatas: "/administration/offer-data",
  offerPreviewWithIt: (offerId) =>
    `/api/administration/offer/preview/${offerId}`,
  latest: "/credit/my-preliminary-assessment",
  latestMortgage: "/mortgage/my-preliminary-assessment",
};

/**
 * Offers Store Configuration store
 */

const initState = () => {
  return {
    amount: 0,
    duration: 0,
    mortgagePurpose: null,
    interestPeriod: null,
    greenPurpose: false,
    withPpi: false,
    eligible: true,
    eligibleOffers: [],
    offerDatas: [],
    loaded: false,
    assessmentId: null,
    more: {
      amount: 0,
      duration: 0,
    },
    rawOfferDatas: [],
    offerPreview: {},
  };
};

const actions = {
  fetchLatest: async ({ commit }, isMortgage = false) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(
        isMortgage ? urls.latestMortgage : urls.latest
      );
      commit("saveOffers", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchOfferPreview: async ({ commit }, id) => {
    try {
      let { data } = await axios.get(urls.offerPreviewWithIt(id));
      commit("saveOfferPreview", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchOfferDatas: async ({ commit }, ids) => {
    try {
      let axios = await network.connection();
      let query = "?ids=";
      for (let i in ids) {
        query += ids[i];
        query += ",";
      }
      query = query.substring(0, query.length - 1);
      let { data } = await axios.get(urls.offerDatas + query);
      commit("saveOfferDatas", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchRawOfferDatas: async ({ commit }, ids) => {
    try {
      let axios = await network.connection();
      let query = "?ids=";
      for (let i in ids) {
        query += ids[i];
        query += ",";
      }
      query = query.substring(0, query.length - 1);
      let { data } = await axios.get(urls.offerDatas + query);
      commit("saveRawOfferDatas", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  unload: (state) => {
    Vue.set(state, "loaded", false);
  },
  saveOfferPreview: (state, data) => {
    Vue.set(state, "offerPreview", data);
  },
  saveOffers: (state, data) => {
    Vue.set(state, "loaded", false);
    Vue.set(state, "eligible", data.isEligible);
    Vue.set(state, "eligibleOffers", []);
    Vue.set(state, "assessmentId", data.id);
    for (let o in data.offers) {
      if (data.offers[o].decision !== "DTI_FAILED")
        state.eligibleOffers.push(data.offers[o]);
    }
    Vue.set(state.more, "amount", data.amount);
    Vue.set(state.more, "duration", 0);
    Vue.set(state, "amount", data.amount);
    Vue.set(state, "duration", data.duration);
    Vue.set(state, "mortgagePurpose", data.mortgagePurpose);
    Vue.set(state, "interestPeriod", data.interestPeriod);
    Vue.set(state, "greenPurpose", data.greenPurpose);
    Vue.set(state, "withPpi", data.withPpi);
    Vue.set(state, "dynamicData", data.dynamicData);
  },
  saveOfferDatas: (state, offerDatas) => {
    Vue.set(state, "offerDatas", []);
    let findMe = (id) => {
      for (let eo in state.eligibleOffers) {
        if (state.eligibleOffers[eo].offerId === id) {
          return state.eligibleOffers[eo];
        }
      }
    };
    for (let o in offerDatas) {
      let calc = findMe(offerDatas[o].id);
      state.offerDatas.push({
        data: offerDatas[o],
        calculation: calc,
      });
      if (
        !!calc &&
        calc.dtiDecision === 1 &&
        calc.dtiAmount > state.more.amount
      ) {
        Vue.set(state.more, "amount", calc.dtiAmount);
        Vue.set(state.more, "duration", calc.dtiDuration);
      }
    }
    Vue.set(state, "loaded", true);
  },
  saveRawOfferDatas: (state, offerDatas) => {
    Vue.set(state, "rawOfferDatas", []);
    for (let o in offerDatas) {
      state.rawOfferDatas.push(offerDatas[o]);
    }
  },
  setDuration: (state, duration) => {
    state.duration = duration;
  },
  setAmount: (state, amount) => {
    state.amount = amount;
  },
  setMortgagePurpose: (state, mortgagePurpose) => {
    state.mortgagePurpose = mortgagePurpose;
  },
  setInterestPeriod: (state, interestPeriod) => {
    state.interestPeriod = interestPeriod;
  },
  setGreenPurpose: (state, greenPurpose) => {
    state.greenPurpose = greenPurpose;
  },
  setWithPPI: (state, withPpi) => {
    state.withPpi = withPpi;
  },
  setAssesmentId: (state, assesmentId) => {
    state.assessmentId = assesmentId;
  },
};

const getters = {
  loaded: (state) => {
    return state.loaded;
  },
  amount: (state) => {
    return state.amount;
  },
  duration: (state) => {
    return state.duration;
  },
  mortgagePurpose: (state) => {
    return state.mortgagePurpose;
  },
  interestPeriod: (state) => {
    return state.interestPeriod;
  },
  greenPurpose: (state) => {
    return state.greenPurpose;
  },
  withPpi: (state) => {
    return state.withPpi;
  },
  offers: (state) => {
    return state.offerDatas;
  },
  rawOffers: (state) => {
    return state.rawOfferDatas;
  },
  offerPreview: (state) => {
    return state.offerPreview;
  },
  eligible: (state) => {
    return state.eligible;
  },
  offerIds: (state) => {
    let ids = [];
    for (let eo in state.eligibleOffers) {
      ids.push(state.eligibleOffers[eo].offerId);
    }
    return ids;
  },
  more: (state) => {
    return state.more;
  },
  assessmentId: (state) => {
    return state.assessmentId;
  },
  dynamicData: (state) => {
    return state.dynamicData;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
