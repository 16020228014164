import { newLogger } from "@/utils/util";
import network from "../../../utils/network";

let logger = newLogger("AdministrationSpecialFieldStore");

const urls = {
  speciaFieldWithId: (id) => `/administration/special-field/${id}`,
};

/**
 * Administration SpecialField store
 */

const initState = () => {
  return {
    speciaFieldData: {},
  };
};

const actions = {
  fetchOne: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.speciaFieldWithId(id));
      commit("saveOne", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveOne: (state, data) => {
    state.speciaFieldData[data.id] = data;
  },
};

const getters = {
  get: (state) => (id) => {
    return state.speciaFieldData[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
