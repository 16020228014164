import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "../../../utils/network";

let logger = newLogger("AdministrationStatementPromptStore");

const urls = {
  statementWithId: (id) => `/administration/statement/${id}`,
};

/**
 * Administration Statement Prompt store
 */

const initState = () => {
  return {
    statementData: {},
  };
};

const actions = {
  fetchOne: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.statementWithId(id));
      commit("saveOne", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveOne: (state, data) => {
    Vue.set(state.statementData, data.id, data);
  },
};

const getters = {
  get: (state) => (id) => {
    return state.statementData[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
