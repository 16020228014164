import { newLogger } from "@/utils/util";
import axios from "axios";
import Vue from "vue";

let logger = newLogger("configStore");

const urls = {
  autoloadConfigs: "/api/administration/ui-configs/portal",
  configWithIdentifier: (identifier) =>
    `/api/administration/ui-config/portal/${identifier}`,
};

const initState = () => {
  return {
    autoloadConfigs: [],
    configs: {},
  };
};

const actions = {
  fetchAutoloadConfigs: async ({ commit }) => {
    try {
      const { data: configs } = await axios.get(urls.autoloadConfigs);

      commit("setAutoloadConfigs", configs);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchConfig: async ({ commit }, identifier) => {
    try {
      const { data: config } = await axios.get(
        urls.configWithIdentifier(identifier)
      );

      commit("setConfig", {
        identifier,
        config,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  setAutoloadConfigs: (state, configs) => {
    Vue.set(state, "autoloadConfigs", configs);
  },
  setConfig: (state, { identifier, config }) => {
    Vue.set(state.configs, identifier, config);
  },
};

const getters = {
  getAutoloadConfigValue: (state) => (identifier) => {
    let configsByIdentifier = state.autoloadConfigs.filter(
      (config) => config.identifier === identifier
    );
    if (configsByIdentifier.length === 1) {
      return configsByIdentifier.shift().value;
    }
    return null;
  },

  getConfig: (state) => (identifier) => {
    return state.configs[identifier];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
