import { library, config } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

config.autoAddCss = false;

const FontAwesome = {
  install(Vue) {
    library.add(fas);
    library.add(far);
    Vue.component("vue-fontawesome", FontAwesomeIcon);
  },
};

export default FontAwesome;
