import Vue from "vue";

const eventBus = new Vue();
export default eventBus;

export const EVENTS = {
  FLOW_VALIDATION: "FLOW_VALIDATION",
  TOKENS_LOADED: "TOKENS_LOADED",
  STATUS_UPDATED: "STATUS_UPDATED",
  OPEN_CALL_ME_BACK_MODAL: "OPEN_CALL_ME_BACK_MODAL",
  OPEN_BASKET_MODAL: "OPEN_BASKET_MODAL",
  PRELIM_TYPE_CHANGED: "PRELIM_TYPE_CHANGED",
};
