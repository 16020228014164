import Vue from "vue";
import { newLogger } from "../../../utils/util";
import network from "../../../utils/network";
import _forEach from "lodash.foreach";
import _find from "lodash.find";
import _filter from "lodash.filter";
import _every from "lodash.every";

let logger = newLogger("MerchantStore");

const urls = {
  categories: "/merchant/merchant-categories/customer",
  merchants: "/merchant/merchants/customer",
  merchant: (id) => `/merchant/merchant/customer/${id}`,
};

const initState = () => {
  return {
    categories: [],
    merchants: [],
    merchant: {},
  };
};

const actions = {
  fetchOne: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.merchantWithId(id));
      commit("saveOne", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchCategories: async ({ commit }) => {
    try {
      let axios = await network.connection();
      const { data } = await axios.get(urls.categories);
      commit("saveCategories", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMerchants: async ({ commit }, query = {}) => {
    try {
      const { fields = [], text = "" } = query;
      let axios = await network.connection();
      const { data } = await axios.get(urls.merchants);
      const filteredData = _filter(data, (d) =>
        _every(fields, (f) => d[f].toLowerCase().includes(text))
      );
      _forEach(filteredData, (d) => {
        commit("saveMerchant", { data: d, id: d.id });
      });
      commit("saveMerchants", filteredData);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMerchant: async ({ commit }, id) => {
    try {
      let axios = await network.connection();
      const { data } = await axios.get(urls.merchant(id));
      commit("saveMerchant", { data, id });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveCategories: (state, data) => {
    Vue.set(state, "categories", data);
  },
  saveMerchants: (state, data) => {
    Vue.set(state, "merchants", data);
  },
  saveMerchant: (state, { data, id }) => {
    Vue.set(state.merchant, id, data);
  },
};

const getters = {
  getCategories: (state) => {
    return state.categories;
  },
  getFilledCategories: (state, getters) =>
    Object.keys(getters.getMerchantsByCategory).map((mc) =>
      _find(state.categories, (c) => c.id === Number(mc))
    ),
  getMerchants: (state) => {
    return state.merchants;
  },
  getMerchantsByCategory: (state) => {
    const merchantByRole = {};
    _forEach(state.merchants, (merchant) => {
      _forEach(merchant.categories, (category) => {
        if (!merchantByRole[category.id]?.length) {
          merchantByRole[category.id] = [];
        }
        merchantByRole[category.id].push(merchant);
      });
    });
    return merchantByRole;
  },
  getMerchant: (state) => (id) => {
    return state.merchant[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
