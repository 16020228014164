export const SIMPLE_FIELD_TYPE = {
  INPUT: "INPUT",
  SELECT: "SELECT",
  CHECKBOX: "CHECKBOX",
  RADIO: "RADIO",
  DATE_PICKER: "DATE_PICKER",
};

export const FIELD_MASK = {
  PHONE: "PHONE",
  HUF: "HUF",
  BANK_ACCOUNT: "BANK_ACCOUNT",
};

export const DATE_VALIDATION = {
  PAST: "PAST",
  FUTURE: "FUTURE",
};

export const RESTRICTION_TYPE = {
  PUBLIC: "PUBLIC",
  SECURED: "SECURED",
  RESTRICTED: "RESTRICTED",
};

export const PARTNER_TYPE = {
  BANK: "BANK",
  INSURER: "INSURER",
};

export const FLOW_POSITION = {
  INTRO: "INTRO",
  FLOW: "FLOW",
  OVERVIEW: "OVERVIEW",
  SCORING: "SCORING",
  // KYC_PREPARATION: "KYC_PREPARATION",
  // KYC_CHOOSE: "KYC_CHOOSE",
  // KYC_WAITING: "KYC_WAITING",
  CONTRACT_CHECK: "CONTRACT_CHECK",
  SIGN_PREPARATION: "SIGN_PREPARATION",
  SIGN_CHOOSE: "SIGN_CHOOSE",
  SIGN_WAITING: "SIGN_WAITING",
  UNDER_REVIEW: "UNDER_REVIEW",
  OFFLINE_UNDER_REVIEW: "OFFLINE_UNDER_REVIEW",
  RESULT: "RESULT",
  OFFLINE_RESULT: "OFFLINE_RESULT",
  PURCHASE_RESULT: "PURCHASE_RESULT",
  CLOSED: "CLOSED",
  COMPLETED: "COMPLETED",
};

export const CLOSEABLE_FLOW_POSITIONS = [
  FLOW_POSITION.INTRO,
  FLOW_POSITION.FLOW,
  FLOW_POSITION.OVERVIEW,
  FLOW_POSITION.SCORING,
]

export const LOAN_STATUS = {
  UNDER_REVIEW: "UNDER_REVIEW",
  BANK_RESULT: "BANK_RESULT",
  PURCHASE_BANK_RESULT: "PURCHASE_BANK_RESULT",
  CLOSED: "CLOSED",
  COMPLETED: "COMPLETED",
};

export const FLOW_QUESTION_FIELD_TYPE = {
  SIMPLE_FIELD: "SIMPLE_FIELD",
  STATEMENT_PROMPT: "STATEMENT_PROMPT",
  DOCUMENT_PROMPT: "DOCUMENT_PROMPT",
  READONLY_FIELD: "READONLY_FIELD",
  SPECIAL_FIELD: "SPECIAL_FIELD",
};

export const FLOW_QUESTION_FIELD_SUBTYPE = {
  PSD2: "PSD2",
  KYC: "KYC",
  EMAIL_CONFIRM: "EMAIL_CONFIRM",
  BARRIER: "BARRIER",
  REVIEW_SCORE: "REVIEW_SCORE",
  REVIEW_TEXT: "REVIEW_TEXT",
};

export const APPLICATION_TEMPLATE = {
  TRIPLE_OFFER_BOTTOM: "TRIPLE_OFFER_BOTTOM",
  OFFER_ACCEPTANCE_MODAL: "OFFER_ACCEPTANCE_MODAL",
  APPLICATION_INTRO_BOTTOM: "APPLICATION_INTRO_BOTTOM",
  KYC_PREP: "KYC_PREP",
  SIGN_PREP: "SIGN_PREP",
  APPLICATION_IS_CLOSED: "APPLICATION_IS_CLOSED",
  APPLICATION_SUCCESS: "APPLICATION_SUCCESS",
  APPLICATION_FAILURE: "APPLICATION_FAILURE",
};

export const LOGIN_STATUS = {
  ENUM: {
    REGISTRATION_INCOMPLETE: "REGISTRATION_INCOMPLETE",
    PASSWORD_CHANGE_REQUIRED: "PASSWORD_CHANGE_REQUIRED",
    LOGIN_OK: "LOGIN_OK",
  },
};

export const CALL_ME_BACK_TYPE = {
  CBR_BTN: "CBR_BTN",
  OFFLINE_OFFER: "OFFLINE_OFFER",
  NO_OFFER: "NO_OFFER",
  EXIT_INTENT: "EXIT_INTENT",
  FOOTER: "FOOTER",
};

export const STATEMENT_PROMPT_TYPE = {
  DOCUMENT: "DOCUMENT",
  LINK: "LINK",
  DYNAMIC: "DYNAMIC",
};

export const UI_CONFIGS = {
  PASSWORDLESS: "passwordless_login",
  NAME_ON_REG: "name_on_reg",
  PSD2_MOCK_ENABLED: "psd2_mock_enabled",
  PSD2_SYNC_UI_URL: "psd2_sync_ui_url",
  PSD2_BANK_API_URL: "psd2_bank_api_url",
  APPEARANCE: "appearance",
  GTM_ID: "gtm_id",
  EXIT_INTENT_MODAL: "exit_intent_modal",
  EXIT_INTENT_MODAL_MORTGAGE: "exit_intent_modal_mortgage",
  CALL_BACK_REQUEST: "call_back_request",
  CALL_BACK_REQUEST_MORTGAGE: "call_back_request_mortgage",
  INACTIVITY_FEATURE: "inactivity_feature",
  INACTIVITY_MODAL_TIME: "inactivity_modal_time",
  INACTIVITY_LOGOUT_TIME: "inactivity_logout_time",
  OWN_COOKIE_SOLUTION: "own_cookie_solution",
};

export const CUSTOM_APPEARANCE = {
  MBH: "mbh",
};

export const SIGNATURE_PROCESS_STATUS = {
  NOT_READY: "NOT_READY",
  READY: "READY",
  WITHDRAWN: "WITHDRAWN",
  EXPIRED: "EXPIRED",
  ERROR_FOUND: "ERROR_FOUND",
};

export const CORRECTION_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  WITHDRAWN: "WITHDRAWN",
  COMPLETED: "COMPLETED",
  CLOSED: "CLOSED:",
  CANCELLED: "CANCELLED",
};

export const CONDITIONAL_FIELD_OPERATOR = {
  ENUM: {
    AND: "AND",
    OR: "OR",
  },
};

export const TODO_TYPE = {
  ENUM: {
    CORRECTION: "CORRECTION",
    SIGNATURE: "SIGNATURE",
  },
  ICON: {
    CORRECTION: "file-invoice",
    SIGNATURE: "file-signature",
  },
  NAME: {
    CORRECTION: "af:my_todos.card.correction.type",
    SIGNATURE: "af:my_todos.card.signature.type",
  },
  TITLE: {
    CORRECTION: "af:my_todos.card.correction.title",
    SIGNATURE: "af:my_todos.card.signature.title",
  },
  BUTTON: {
    CORRECTION: "af:my_todos.card.correction.open",
    SIGNATURE: "af:my_todos.card.signature.open",
  },
  STATUS: {
    CORRECTION: {
      IN_PROGRESS: "af:my_todos.card.correction.status.in_progress",
      WITHDRAWN: "af:my_todos.card.correction.status.withdrawn",
      COMPLETED: "af:my_todos.card.correction.status.completed",
      CLOSED: "af:my_todos.card.correction.status.closed",
      CANCELLED: "af:my_todos.card.correction.status.cancelled",
    },
    SIGNATURE: {
      IN_PROGRESS: "af:my_todos.card.signature.status.waiting",
      WAITING: "af:my_todos.card.signature.status.accepted",
      ACCEPTED: "af:my_todos.card.signature.status.in_progress",
      SIGNED: "af:my_todos.card.signature.status.signed",
      CLOSED: "af:my_todos.card.signature.status.closed",
    },
  },
  OUTCOME: {
    CORRECTION: {
      SUCCESSFUL: "af:my_todos.card.correction.outcome.successful",
      UNSUCCESSFUL: "af:my_todos.card.correction.outcome.unsuccessful",
    },
    SIGNATURE: {
      ERROR_FOUND: "af:my_todos.card.correction.outcome.error_found",
      CANCEL: "af:my_todos.card.correction.outcome.cancel",
      WITHDRAWN: "af:my_todos.card.correction.outcome.withdrawn",
      EXPIRED: "af:my_todos.card.correction.outcome.expired",
    },
  },
};

export const PURCHASE_STATUS = {
  CREATED: "CREATED",
  LINKED: "LINKED",
  APPLICATION_STARTED: "APPLICATION_STARTED",
  APPLICATION_UNDER_REVIEW: "APPLICATION_UNDER_REVIEW",
  LOAN_APPROVED: "LOAN_APPROVED",
  MONEY_TRANSFERRED: "MONEY_TRANSFERRED",
  PAID: "PAID",
  REVOKED: "REVOKED",
  CANCELLED: "CANCELLED",
  DENIED: "DENIED",
};

export const ClOSEABLE_PURCHASE_STATUSES = [
  PURCHASE_STATUS.LINKED,
  PURCHASE_STATUS.LOAN_APPROVED,
  PURCHASE_STATUS.APPLICATION_UNDER_REVIEW,
  PURCHASE_STATUS.APPLICATION_STARTED,
];

export const PRELIM_TYPE = {
  KEY: "preliminaryAssessmentType",
  PSD2: "PSD2",
  MANUAL: "MANUAL",
};

export const PRELIM_FIXED_STEPS = {
  AMOUNT_AND_TERM: 0,
  // TYPE_SELECT: 1,
};

export const MORTGAGE_PRELIM_FIXED_STEPS = {
  AMOUNT_AND_TERM: 0,
};

export const PRELIM_PSD2_FIXED_STEPS = {
  SYNC: 2,
};

export const PSD2_FIELD_SOURCE = {
  PRELIM: "PRELIM",
  LOAN_APPLICATION: "LOAN_APPLICATION",
  CORRECTION: "CORRECTION",
};

export const PSD2_SYNC_OUTCOME = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export const PSD2_SYNC_START_OUTCOME = {
  NEEDS_ACCOUNT_REGISTRATION: "NEEDS_ACCOUNT_REGISTRATION",
  CAN_START_A8_SYNC: "CAN_START_A8_SYNC",
  NEEDS_BANK_SELECT: "NEEDS_BANK_SELECT",
  ALREADY_IN_PROGRESS: "ALREADY_IN_PROGRESS",
};
export const SCORE_DECISION = {
  ENUM: {
    APPROVED: "APPROVED",
    REJECT: "REJECT",
    MANUAL: "MANUAL",
    MANUALLY_APPROVED: "MANUALLY_APPROVED",
    MANUALLY_REJECTED: "MANUALLY_REJECTED",
    DISALLOWED: "DISALLOWED",
  },
  TOKEN: {
    APPROVED: "af:score.decision_type.approved",
    REJECT: "af:score.decision_type.reject",
    MANUAL: "af:score.decision_type.manual",
    MANUALLY_APPROVED: "af:score.decision_type.manually_approved",
    MANUALLY_REJECTED: "af:score.decision_type.manually_rejected",
  },
  COLOR: {
    APPROVED: "green",
    REJECT: "red",
    MANUAL: "blue",
    MANUALLY_APPROVED: "green",
    MANUALLY_REJECTED: "red",
  },
};

export const APPLICATION_MODE = {
  LOAN: "LOAN",
  MORTGAGE: "MORTGAGE",
};

export const LOCAL_STORAGE_KEYS = {
  APPLICATION_INVITE_CODE: "application-invite-code",
};

export const KYC_STATUSES = {
  NOT_STARTED: "NOT_STARTED",
  CREATED: "CREATED",
  STARTED: "STARTED",
  PENDING: "PENDING",
  REVIEWING: "REVIEWING",
  WAITING_FOR_4_EYES: "WAITING_FOR_4_EYES",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};
