import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "../../../utils/network";

let logger = newLogger("AdministrationSimpleFieldStore");

const urls = {
  fieldWithId: (id) => `/administration/field/${id}`,
};

/**
 * Administration Simple Field store
 */

const initState = () => {
  return {
    fieldData: {},
  };
};

const actions = {
  fetchOne: async ({ commit }, fieldId) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.fieldWithId(fieldId));
      commit("saveOne", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveOne: (state, data) => {
    Vue.set(state.fieldData, data.id, data);
  },
};

const getters = {
  get: (state) => (id) => {
    return state.fieldData[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
