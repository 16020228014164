import { newLogger } from "@/utils/util";
import Vue from "vue";
import network from "../../../utils/network";

let logger = newLogger("KYCConfigStore");

const urls = {
  list: "/administration/kyc-type-info",
};

const initState = () => {
  return {
    configs: {},
  };
};

const actions = {
  fetchConfigs: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data: configs } = await axios.get(urls.list);

      commit("saveConfigs", configs);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveConfigs: (state, configs) => {
    let map = {};
    for (let c in configs) {
      map[configs[c].id] = configs[c];
    }
    Vue.set(state, "configs", map);
  },
};

const getters = {
  getConfigs: (state) => {
    return state.configs;
  },
  getConfig: (state) => (id) => {
    return state.configs[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
