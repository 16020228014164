import session from "./session";
import loading from "./loading";
import i18n from "./i18n";
import drawer from "./drawer";
import registrationConfiguration from "./acceptancerequirements";
import preliminaryAssessment from "./preliminaryassessment";
import defaultOffer from "./defaultoffer";
import onboarding from "./onboarding";
import loanApplication from "./loanapplication";
import tripleOfferConfig from "./tripleofferconfig";
import documentPromptStore from "./fields/documentPromptStore";
import readOnlyFieldStore from "./fields/readOnlyFieldStore";
import simpleFieldStore from "./fields/simpleFieldStore";
import specialFieldStore from "./fields/specialFieldStore";
import statementPromptStore from "./fields/statementPromptStore";
import psd2FieldStore from "./fields/psd2FieldStore";
import acceptanceRequirements from "./acceptancerequirements";
import offers from "./offers";
import template from "./template";
import passwordPolicy from "./password-policy";
import profile from "./profile";
import image from "./image";
import kycConfig from "./kyc-config";
import filterSettings from "./filtersettings";
import callMeBack from "./call-me-back";
import config from "./config";
import myTodo from "./my-todo";
import correction from "./correction";
import purchase from "./purchase";
import merchant from "./merchant";
import psd2Sync from "./psd2-sync";
import psd2 from "./psd2";
import mortgageApplication from "@/store/modules/mortgageapplication";
import mortgagePreliminaryAssessment from "@/store/modules/mortgagepreliminaryassesment";
import prepayment from "./prepayment";

/**
 * Gathering all modules to export into the Vuex Store
 */
export default {
  session,
  loading,
  i18n,
  drawer,
  registrationConfiguration,
  preliminaryAssessment,
  defaultOffer,
  onboarding,
  loanApplication,
  offers,
  tripleOfferConfig,
  documentPromptStore,
  readOnlyFieldStore,
  simpleFieldStore,
  specialFieldStore,
  statementPromptStore,
  acceptanceRequirements,
  template,
  passwordPolicy,
  profile,
  image,
  kycConfig,
  filterSettings,
  callMeBack,
  config,
  myTodo,
  correction,
  purchase,
  merchant,
  psd2FieldStore,
  psd2Sync,
  psd2,
  mortgageApplication,
  mortgagePreliminaryAssessment,
  prepayment,
};
