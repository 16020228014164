import { newLogger } from "@/utils/util";
import i18n from "@/plugins/i18n";
import network from "../../../utils/network";
import axios from "axios";
import Vue from "vue";
import eventBus, { EVENTS } from "@/utils/eventBus";

let logger = newLogger("i18nStore");

const urls = {
  publicTokens: (lang) => `/api/tokens/public/${lang}`,
  internalTokens: (lang) => `/tokens/internal/${lang}`,
};

/**
 * i18n plugin's store
 * Stores the i18n messages, manages the vue-i18n plugin
 */

const initState = () => {
  return {
    tokens: {},
  };
};

const actions = {
  fetchTokensByLanguage: async ({ commit }, lang) => {
    try {
      const { data: tokens } = await axios.get(urls.publicTokens(lang));

      commit("setTokensForLanguage", {
        lang,
        tokens,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchInternalTokensByLanguage: async ({ commit }, lang) => {
    try {
      const axios = await network.connection();
      const { data: tokens } = await axios.get(urls.internalTokens(lang));

      commit("setTokensForLanguage", {
        lang,
        tokens,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  setTokensForLanguage: (state, { lang, tokens }) => {
    if (state.tokens[lang]) {
      let currentTokens = state.tokens[lang];
      Vue.set(state.tokens, lang, Object.assign({}, tokens, currentTokens));
    } else {
      Vue.set(state.tokens, lang, tokens);
    }

    i18n.locale = lang;
    i18n.setLocaleMessage(lang, state.tokens[lang]);
    eventBus.$emit(EVENTS.TOKENS_LOADED);
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: {},
};
