import Minilog from "minilog";
import StringMask from "string-mask";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { FIELD_MASK, UI_CONFIGS } from "@/utils/const";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  months: [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
  ],
});

const LOCALSTORAGE_CONFIG_KEY = "";

/**
 * Sets up a configuration JSON in localStorage and stores the value on the key
 * @param key
 * @param value
 */
export function localStore(key, value) {
  let stored = localStorage.getItem(LOCALSTORAGE_CONFIG_KEY);
  if (stored !== null) {
    let parsed = JSON.parse(stored);
    parsed[key] = value;
    localStorage.setItem(LOCALSTORAGE_CONFIG_KEY, JSON.stringify(parsed));
  } else {
    let data = {};
    data[key] = value;
    localStorage.setItem(LOCALSTORAGE_CONFIG_KEY, JSON.stringify(data));
  }
}

/**
 * Returns the value stored in the config JSON
 * @param key
 * @returns {undefined|*}
 */
export function localFetch(key) {
  let stored = localStorage.getItem(LOCALSTORAGE_CONFIG_KEY);
  if (stored !== null) {
    // localStorage returns null if not exists
    let parsed = JSON.parse(stored);
    return parsed[key];
  } else {
    return undefined; //parsed[key] returns undefined if not exists, so we do it too
  }
}

export function isDevMode() {
  /* eslint-disable */
    return process.env.NODE_ENV === 'development';
}

export function newLogger(name) {
    if (isDevMode() === true) {
        Minilog.enable();
    }
    return Minilog(name || 'app');
}

export function formatSeconds(seconds) {
    if (!seconds) {
        return "0 perc";
    }
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    minutes = minutes % 60;
    if (days >= 1) hours = hours % 24;
    return `${days ? days + " nap " : " "}${hours ? hours + " óra " : " "}${minutes ? minutes + " perc " : " "}`;
}

export function secondsToMinutes(seconds) {
    if (!seconds) {
      return "00:00";
    }
    let minutes = Math.floor(seconds / 60);
    return `${minutes.toString().padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
}

export function monify(number) {
    if (number === null || number === undefined) return 0;
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatDate(date) {
    if (!date) {
        return undefined;
    }
    return dayjs(date).format('YYYY-MM-DD');
}

export function formatDateWithDots(date) {
  if (!date) {
    return undefined;
  }
  return dayjs(date).format('YYYY.MM.DD.');
}

export function roundTwoDigits(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export function formatDateTime(date) {
    if (!date) {
        return undefined;
    }
    return dayjs(date).format('YYYY.MM.DD. HH:mm');
}

export function formatTimeFromUnix(timestamp) {
  if (!timestamp) {
    return "-";
  }
  return dayjs.unix(timestamp).format('HH:mm');
}

export function dateToBackendOffsetDateTimeFormat(date)  {
  if (date) {
    return dayjs(date).format(
      "YYYY-MM-DD HH:mm:ss+0100"
    );
  }
  return null;
}


export function getMonthName(date){
    return dayjs(date).format('MMMM');
}

export function addDay(date, numberOfDays){
    return dayjs(date).add(numberOfDays, 'day').format('YYYY-MM-DD');
}

export function subtractDay(date, numberOfDays){
    return dayjs(date).subtract(numberOfDays, 'day').format('YYYY-MM-DD');
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function generateNonce() {
  let result = [];
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

export function maskValue(mask, value) {
  if (mask === FIELD_MASK.HUF) {
    if (value) {
      let valueForFormat = typeof value === "string" ? value.replace(/\D/g,'') : value;
      const monified = monify(valueForFormat);
      if (monified === 0) return "";
      return monified;
    }
  } else if (mask === FIELD_MASK.PHONE) {
    if (value) {
      let valueForFormat = typeof value === "string" ? value.replace("+36", "").replaceAll(" ", "").replace(/^06/, '') : value + "";
      let apply;
      if (valueForFormat.length < 1) {
        apply = "";
      }
      else if (valueForFormat.length < 3) {
        apply = new StringMask("00").apply(valueForFormat);
      }
      else if (valueForFormat.length < 6) {
        apply = new StringMask("00 000").apply(valueForFormat);
      }
      else {
        apply = new StringMask("00 000 0000").apply(valueForFormat);
      }
        return apply;
    } else {
        return "";
    }
  }  else if (mask === FIELD_MASK.BANK_ACCOUNT) {
    if (value) {
      let valueForFormat = typeof value === "string" ? value.replace(/\D/g,'') : value;
      let apply = new StringMask("00000000-00000000-00000000").apply(valueForFormat);
      return apply ? apply : "";
    } else {
      return "";
    }
  }
  return value;
}

// Call this in mounted not in created
export async function resetValidator(observer) {
    await observer.validate();
    observer.reset();
}

export function isBrand(brand) {
  return this.$store.getters["config/getAutoloadConfigValue"](UI_CONFIGS.APPEARANCE) === brand;
}

export function creditEnabled() {
  return !(process.env.VUE_APP_DISABLE_CREDIT === 'true');
}

export function checkInactivity(alertThreshold, logoutThreshold, alertCallback, logoutCallback, secondPassedCallback, resetCallback) {
  let idleTime = 0;
  const timer = setInterval(timerIncrement, 1000); // 1 second
  secondPassedCallback(idleTime, timer);

  document.onclick = () => resetTimer();
  document.onkeydown = () => resetTimer();
  document.onscroll = () => resetTimer();
  // document.onmousemove = () => {
  //   if (idleTime < alertThreshold) {
  //     resetTimer();
  //   }
  // };

  function resetTimer() {
    idleTime = 0;
    resetCallback();
  }

  function timerIncrement() {
    idleTime = idleTime + 1;
    secondPassedCallback(idleTime, timer);
    if (idleTime === alertThreshold) {
      alertCallback();
    }
    if (idleTime >= logoutThreshold) {
      clearInterval(timer);
      logoutCallback();
    }
  }
}

export async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}
