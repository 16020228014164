import { newLogger } from "../../../utils/util";

/**
 * The Loader component display loaders for every single request its wired into
 */

let logger = newLogger("loading-vuex");

let fixClass = "loading-indicator__item ";
let classPrefix = "loading-indicator__item";
let activeSuffix = "--active";
let loadingSuffix = "--loading";
let successSuffix = "--success";
let failureSuffix = "--failure";

/**
 * Static state while booting
 */
const state = {
  loaders: [], // the array used for display
  loadingObjects: {}, // the object used internally
};

/**
 * Need to be called from requestors
 */
const actions = {
  startLoading: (context, loadingObject) => {
    context.commit("enableLoading", loadingObject);
  },
  finishLoading: (context, loadingObject) => {
    context.commit("disableLoading", loadingObject);
  },
};

/**
 * Finds the needle in a haystack, based on ID
 */
let findInArray = function (needle, haystack) {
  for (let i = 0; i < haystack.length; ++i) {
    if (haystack[i] && haystack[i].id === needle) {
      return i;
    }
  }
  return -1;
};

/**
 * Delayed remover
 */
let removeLater = function (loadingObjects, loaders, target) {
  loadingObjects[target] = null;
  let index = findInArray(target, loaders);
  if (index !== -1) {
    loaders.splice(index);
  } else {
    logger.warn("Remover couldn't find the element: " + target);
  }
};

/**
 * Delayed disabler
 */
let disableLater = function (loaders, target) {
  let index = findInArray(target, loaders);
  if (index !== -1) {
    if (loaders[index].classes.indexOf(successSuffix) !== -1) {
      loaders[index].classes = fixClass + classPrefix + successSuffix;
    } else {
      loaders[index].classes = fixClass + classPrefix + failureSuffix;
    }
  } else {
    logger.warn("Disabler couldn't find the element: " + target);
  }
};

const mutations = {
  enableLoading: (state, loadingObject) => {
    if (state.loadingObjects[loadingObject.id]) {
      logger.warn("Got an ID that already exists: " + loadingObject.id);
      return;
    }
    loadingObject.classes =
      fixClass + classPrefix + activeSuffix + " " + classPrefix + loadingSuffix;
    state.loadingObjects[loadingObject.id] = loadingObject;
    state.loaders.push(loadingObject);
  },
  disableLoading: (state, loadingObject) => {
    if (!state.loadingObjects[loadingObject.id]) {
      logger.warn("Got an ID that doesn't exists: " + loadingObject.id);
      return;
    }
    let index = findInArray(loadingObject.id, state.loaders);
    let arrayObject = state.loaders[index];
    if (!arrayObject) return;
    arrayObject["message"] = loadingObject.message;
    if (loadingObject.result) {
      // success
      arrayObject.classes =
        fixClass +
        classPrefix +
        activeSuffix +
        " " +
        classPrefix +
        successSuffix;

      window.setTimeout(
        disableLater.bind(null, state.loaders, loadingObject.id),
        2000
      );
      window.setTimeout(
        removeLater.bind(
          null,
          state.loadingObjects,
          state.loaders,
          loadingObject.id
        ),
        2300
      );
    } else {
      // failure
      arrayObject.classes =
        fixClass +
        classPrefix +
        activeSuffix +
        " " +
        classPrefix +
        failureSuffix;

      window.setTimeout(
        disableLater.bind(null, state.loaders, loadingObject.id),
        5000
      );
      window.setTimeout(
        removeLater.bind(
          null,
          state.loadingObjects,
          state.loaders,
          loadingObject.id
        ),
        5300
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
