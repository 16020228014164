import { newLogger } from "@/utils/util";
import network from "../../../utils/network";

let logger = newLogger("AdministrationFilterSettings");

const urls = {
  filterSettings: "/administration/offer/calculation-config/filter-settings",
  mortgageFilterSettings:
    "/administration/mortgage/offer/calculation-config/filter-settings",
};

/**
 * Administration Calculation Config Filter Settings Store
 */

const initState = () => {
  return {
    filterSettings: {},
  };
};

const actions = {
  fetchFilterSettings: async ({ commit }) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.filterSettings);
      commit("save", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMortgageFilterSettings: async ({ commit }) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.mortgageFilterSettings);
      commit("save", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  save: (state, data) => {
    state.filterSettings = data;
  },
};

const getters = {
  get: (state) => {
    return state.filterSettings;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
