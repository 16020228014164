import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "../../../utils/network";
import {
  CONDITIONAL_FIELD_OPERATOR,
  FLOW_QUESTION_FIELD_SUBTYPE,
  FLOW_QUESTION_FIELD_TYPE,
  KYC_STATUSES,
  LOCAL_STORAGE_KEYS,
  SIMPLE_FIELD_TYPE,
  STATEMENT_PROMPT_TYPE,
} from "@/utils/const";

let logger = newLogger("MortgageApplicationStore");

const urls = {
  createMortgageApplication: "/mortgage/mortgage-application", // POST
  myMortgageApplications: "/mortgage/my-mortgage-applications", // GET
  myMortgageApplication: `/mortgage/my-mortgage-application`, // GET
  myMortgageApplicationWithId: (id) =>
    `/mortgage/my-mortgage-application/${id}`, // GET

  getSigningDocuments: `/mortgage/my-mortgage-application/documents-for-signing`,
  /*
      getScoringDecision: (applicationId) =>
    `/mortgage/my-mortgage-application/scoring-decision/${applicationId}`,
*/
  signatureErrorFound: `/mortgage/mortgage-application-signature/error-found`,
  getPreviousDocuments: (promptId) =>
    `/mortgage/my-mortgage-application/document/${promptId}`,
  pollStatus: `/mortgage/my-mortgage-application/status`, // GET
  getFlow: `/mortgage/my-mortgage-application/flow`, // GET
  getDynamicStatementAttachment: (questionId) =>
    `/mortgage/my-mortgage-application/dynamic-statement-document/${questionId}`, // GET

  removeData: (identifier) =>
    `/mortgage/my-mortgage-application/data/${identifier}`, // DELETE
  updateData: `/mortgage/my-mortgage-application/data`, // PATCH
  updatePosition: `/mortgage/my-mortgage-application/update-position`, // PATCH
  close: `/mortgage/my-mortgage-application/close`, // POST
  finish: `/mortgage/my-mortgage-application/finish`, // POST
  uploadFile: `/mortgage/my-mortgage-application/document`, // PATCH
  deleteFile: (documentId) =>
    `/mortgage/my-mortgage-application/document/${documentId}`, // DELETE

  startKyc: `/mortgage/my-mortgage-application/identification/start`, // POST
  startSign: `/mortgage/my-mortgage-application/document-signing/start`, // POST
  kycStatus: `/mortgage/my-mortgage-application/identification/status`, // GET
  kycOpenings: `/mortgage/mortgage-application/openings`, // GET
  // startScoringEvaluation: `/mortgage/mortgage-application/evaluate-scoring`,

  baseData: (applicationId) =>
    `/mortgage/my-mortgage-application-base-data/${applicationId}`,

  barrier: (questionId) =>
    `/mortgage/my-mortgage-application/barrier-allowed/${questionId}`,
  inviteOffer: (inviteCode) => `/mortgage/get-invite-offer/${inviteCode}`,
};

/**
 * Mortgage Application store
 */

const initState = () => {
  return {
    mortgageApplication: {},
    mortgageApplicationWithId: {},
    mortgageApplications: [],
    selectedOffer: {},
    flow: [],
    openedBarriers: [],
    applicationData: {},
    dynamicStatementAttachments: {},
    signatureProcess: {},
    kycRecord: {},
    loaded: false,
    kycMobile: false,
    signMobile: false,
    baseData: {},
    inviteOffer: {},
  };
};

const actions = {
  createApplication: async ({ commit }, request) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.post(
        urls.createMortgageApplication,
        request
      );

      commit("saveMortgageApplication", data);

      localStorage.removeItem(LOCAL_STORAGE_KEYS.APPLICATION_INVITE_CODE);

      return Promise.resolve({ offerId: data.offerId, flowId: data.flowId });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  loadEverything: async ({ dispatch, commit }) => {
    let data = await dispatch("fetchApplication");
    await dispatch("fetchFlow", data.flowId);
    await dispatch("fetchOffer", {
      offerId: data.offerId,
      mortgageApplicationId: data.id,
    });
    commit("loaded");
    return true;
  },
  fetchSignatureProcess: async ({ commit }) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.getSigningDocuments);
      commit("saveSignatureProcess", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  getDynamicStatementAttachment: async ({ commit }, questionId) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(
        urls.getDynamicStatementAttachment(questionId)
      );
      commit("saveDynamicStatementAttachment", {
        data: data,
        questionId: questionId,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchPreviousDocuments: async ({}, promptId) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.getPreviousDocuments(promptId));
      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMyApplications: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.myMortgageApplications);

      commit("saveMortgageApplications", data);

      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchApplication: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.myMortgageApplication);

      commit("saveMortgageApplication", data);

      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchAndGetScoringDecision: async ({}, applicationId) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.getScoringDecision(applicationId));
      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchApplicationWithId: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.myMortgageApplicationWithId(id));

      commit("saveMortgageApplicationWithId", { data: data, id: id });

      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchFlow: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.getFlow);

      commit("saveFlow", data);

      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchOffer: async (
    { commit },
    { offerId: id, mortgageApplicationId: mortgageApplicationId }
  ) => {
    const offerUrls = {
      offerDatas: "/administration/offer-data",
      applicationBaseData: (mortgageApplicationId) =>
        `/mortgage/my-mortgage-application-base-data/${mortgageApplicationId}`,
    };
    try {
      let axios = await network.connection();
      let baseDataResponse = await axios.get(
        offerUrls.applicationBaseData(mortgageApplicationId)
      );

      let query = `?ids=${id}`;
      let { data } = await axios.get(offerUrls.offerDatas + query);
      let offer = {
        calculation: baseDataResponse.data,
        data: data[0],
      };
      commit("saveOffer", offer);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  pollStatus: async ({ commit }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.pollStatus);

      commit("updateStatus", data);

      return Promise.resolve(data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  saveDynamicData: async ({ commit }, { key, value }) => {
    try {
      commit("updateDynamicData", { key, value });
      const axios = await network.connection();
      await axios.patch(urls.updateData, {
        key,
        value,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  removeDynamicData: async ({ commit }, key) => {
    try {
      commit("deleteFromDynamicData", key);
      const axios = await network.connection();
      await axios.delete(urls.removeData(key));
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  close: async ({}) => {
    // todo local update
    try {
      const axios = await network.connection();
      await axios.post(urls.close);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  finish: async () => {
    try {
      const axios = await network.connection();
      await axios.post(urls.finish);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  signatureErrorFound: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.patch(urls.signatureErrorFound, request);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  savePosition: async ({ commit }, { flowPosition, currentFlowStepIndex }) => {
    try {
      const axios = await network.connection();
      await axios.patch(urls.updatePosition, {
        flowPosition: flowPosition,
        currentFlowIndex: currentFlowStepIndex,
      });

      commit("updatePosition", {
        flowPosition,
        currentFlowStepIndex,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  setKycMobile: ({ commit }, val) => {
    commit("updateKycMobile", val);
  },
  setSignMobile: ({ commit }, val) => {
    commit("updateSignMobile", val);
  },
  startKyc: async ({ commit }, kycType) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.post(urls.startKyc, { type: kycType });

      commit("saveKyc", data);
      // commit("updatePosition", {
      //   flowPosition: "KYC_WAITING",
      //   currentFlowStepIndex: false,
      // });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchKycStatus: async ({ commit }) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.kycStatus);
      if (data) {
        commit("saveKyc", data);
      }
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchKycOpenings: async () => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.kycOpenings);
      return data;
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  startSign: async ({ commit }, sigType) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.post(urls.startSign, { type: sigType });

      commit("saveKyc", data);
      // commit("updatePosition", {
      //   flowPosition: "SIGN_WAITING",
      //   currentFlowStepIndex: false,
      // });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  startScoringEvaluation: async ({}) => {
    try {
      const axios = await network.connection();
      await axios.patch(urls.startScoringEvaluation);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  uploadFile: async ({}, { file, promptId, promptIdentifier }) => {
    try {
      const axios = await network.connection();
      const formData = new FormData();
      formData.append("file", file);
      formData.append("promptId", promptId);
      formData.append("promptIdentifier", promptIdentifier);
      await axios.patch(urls.uploadFile, formData);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  deleteFile: async ({}, documentId) => {
    try {
      const axios = await network.connection();
      await axios.delete(urls.deleteFile(documentId));
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchBaseData: async ({ commit }, mortgageApplicationId) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.baseData(mortgageApplicationId));
      commit("saveBaseData", { data, mortgageApplicationId });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  checkBarrier: async ({ commit }, { questionId, applicationId }) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.barrier(questionId));

      if (data) {
        commit("openBarrier", {
          questionId: questionId,
          applicationId: applicationId,
        });
      }

      return Promise.resolve(data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchInviteOffer: async ({ commit }, inviteCode) => {
    try {
      const axios = await network.connection();
      const { data } = await axios.get(urls.inviteOffer(inviteCode));

      if (data) {
        commit("saveInviteOffer", data);
      }

      return Promise.resolve(data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveMortgageApplication: (state, data) => {
    Vue.set(state, "applicationData", JSON.parse(data.dynamicData));
    Vue.set(state, "mortgageApplication", data);
    if (!state.mortgageApplication.kycTypes) {
      state.mortgageApplication.kycTypes = [];
    }
    if (!state.mortgageApplication.sigTypes) {
      state.mortgageApplication.sigTypes = [];
    }
    if (data.identificationStatuses && data.identificationStatuses.length)
      if (data.identificationStatuses.length === 1) {
        Vue.set(state, "kycRecord", data.identificationStatuses[0]);
      } else {
        let records = data.identificationStatuses.sort(
          (a, b) => a.externalId - b.externalId
        );
        Vue.set(state, "kycRecord", records[records.length - 1]);
      }
  },
  saveMortgageApplications: (state, data) => {
    Vue.set(state, "mortgageApplications", data);
  },
  saveMortgageApplicationWithId: (state, { data, id }) => {
    Vue.set(state.mortgageApplicationWithId, id, data);
  },
  saveSignatureProcess: (state, data) => {
    Vue.set(state, "signatureProcess", data);
  },
  saveDynamicStatementAttachment: (state, { data, questionId }) => {
    Vue.set(state.dynamicStatementAttachments, questionId, data);
  },
  saveOffer: (state, offer) => {
    Vue.set(state, "selectedOffer", offer);
  },
  saveFlow: (state, flow) => {
    if (state.flow.length) {
      Vue.set(state, "flow", []);
    }
    let steps = flow.steps.sort((a, b) => {
      return a.rowIndex - b.rowIndex;
    });
    for (let s in steps) {
      steps[s].questions = steps[s].questions.filter((q) => !q.hidden);
      state.flow.push(steps[s]);
    }
  },
  updateDynamicData: (state, { key, value }) => {
    Vue.set(state.applicationData, key, value);
  },
  deleteFromDynamicData: (state, key) => {
    Vue.delete(state.applicationData, key);
  },
  updatePosition: (state, { flowPosition, currentFlowStepIndex }) => {
    Vue.set(state.mortgageApplication, "currentPosition", flowPosition);
    if (currentFlowStepIndex || currentFlowStepIndex === 0)
      Vue.set(
        state.mortgageApplication,
        "currentFlowIndex",
        currentFlowStepIndex
      );
  },
  loaded: (state) => {
    Vue.set(state, "loaded", true);
  },
  updateKycMobile: (state, val) => {
    Vue.set(state, "kycMobile", val);
  },
  updateSignMobile: (state, val) => {
    Vue.set(state, "signMobile", val);
  },
  saveKyc: (state, data) => {
    Vue.set(state, "kycRecord", data);
  },
  updateStatus: (state, data) => {
    Vue.set(state.mortgageApplication, "currentStatus", data.currentStatus);
    Vue.set(
      state.mortgageApplication,
      "currentStatusResult",
      data.currentStatusResult
    );
    Vue.set(state.mortgageApplication, "currentPosition", data.currentPosition);
  },
  saveBaseData: (state, { data, mortgageApplicationId }) => {
    Vue.set(state.baseData, mortgageApplicationId, data);
  },
  openBarrier: (state, { questionId, applicationId }) => {
    state.openedBarriers.push({
      questionId: questionId,
      applicationId: applicationId,
    });
  },
  saveInviteOffer: (state, data) => {
    Vue.set(state, "inviteOffer", data);
  },
};

const getters = {
  getActiveOffer: (state) => {
    return state.selectedOffer;
  },
  getActiveApplication: (state) => {
    return state.mortgageApplication;
  },
  getApplication: (state) => (id) => {
    return state.mortgageApplicationWithId[id];
  },
  loaded: (state) => {
    return state.loaded;
  },
  flow: (state) => {
    return state.flow;
  },
  getFlowQuestionAnswer: (state) => (identifier) => {
    return state.applicationData[identifier];
  },
  getDynamicStatementAttachment: (state) => (questionId) => {
    return state.dynamicStatementAttachments[questionId];
  },

  isKycMobile: (state) => {
    return state.kycMobile;
  },
  getSignatureProcess: (state) => {
    return state.signatureProcess;
  },
  isSignMobile: (state) => {
    return state.signMobile;
  },
  applicationStatus: (state) => {
    return state.mortgageApplication.currentStatus;
  },
  applicationStatusResult: (state) => {
    return state.mortgageApplication.currentStatusResult;
  },
  canContinue: (state, getters, rootState) => (step) => {
    for (let q in state.flow[step].questions) {
      let question = state.flow[step].questions[q];
      let field;
      switch (question.fieldType) {
        case FLOW_QUESTION_FIELD_TYPE.SIMPLE_FIELD:
          field = rootState.simpleFieldStore.fieldData[question.fieldId];
          break;
        case FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT:
          field =
            rootState.statementPromptStore.statementData[question.fieldId];
          break;
        case FLOW_QUESTION_FIELD_TYPE.DOCUMENT_PROMPT:
          field = rootState.documentPromptStore.documentData[question.fieldId];
          break;
        case FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD:
          if (question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.PSD2) {
            field = rootState.psd2FieldStore.psd2FieldData[question.fieldId];
          }
          break;
        case FLOW_QUESTION_FIELD_TYPE.READONLY_FIELD:
          continue;
      }
      if (question.isRequired) {
        let needAnswer;
        if (question.isConditional) {
          for (const condition of question.conditions) {
            let index = question.conditions.indexOf(condition);

            let dependency =
              rootState.simpleFieldStore.fieldData[
                condition.conditionalFieldId
              ];

            let evaluate;

            let answer = state.applicationData[dependency.identifier];
            if (dependency.type === SIMPLE_FIELD_TYPE.CHECKBOX) {
              if (answer) {
                let answerObject = JSON.parse(answer);
                evaluate = answerObject.includes(
                  condition.conditionalFieldAnswer
                );
              } else {
                evaluate = false;
              }
            } else {
              evaluate = answer === condition.conditionalFieldAnswer;
            }

            if (index === 0) {
              needAnswer = evaluate;
            } else {
              let operator =
                question.conditions[index - 1].conditionalFieldOperator;
              if (CONDITIONAL_FIELD_OPERATOR.ENUM.AND === operator) {
                needAnswer = needAnswer && evaluate;
              } else if (CONDITIONAL_FIELD_OPERATOR.ENUM.OR === operator) {
                needAnswer = needAnswer || evaluate;
              }
            }
          }

          if (needAnswer) {
            if (
              question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
              question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.PSD2
            ) {
              if (!field.completed) {
                console.warn("Conditionally required PSD2 not synced!");
                return false;
              }
            } else if (
              question.fieldType ===
                FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT &&
              field.type === STATEMENT_PROMPT_TYPE.DYNAMIC
            ) {
              if (
                !state.applicationData[field.identifier + "_" + question.id]
              ) {
                console.warn(" Required failed!");
                return false;
              }
            } else {
              if (!state.applicationData[field.identifier]) {
                console.warn("Conditionally required failed!");
                return false;
              }
            }

            if (question.isHardKo) {
              if (
                state.applicationData[field.identifier] === question.koAnswer
              ) {
                console.warn("Conditionally KO failed!");
                return false;
              }
            }
            if (
              field &&
              field.type === SIMPLE_FIELD_TYPE.INPUT &&
              field.validation
            ) {
              let value = state.applicationData[field.identifier];

              if (value === undefined) {
                value = "";
              }

              const regex = new RegExp(field.validation);
              if (!regex.test(value)) {
                console.warn("Conditionally regex validation failed!");
                return false;
              }
            }

            if (
              !question.isConditional &&
              field &&
              field.type === SIMPLE_FIELD_TYPE.DATE_PICKER &&
              field.validation
            ) {
              let value = state.applicationData[field.identifier];
              if (value && field.validation) {
                let today = new Date();
                let dateValue = new Date(value);
                today.setHours(0, 0, 0, 0);
                dateValue.setHours(0, 0, 0, 0);
                if (field.validation === "PAST" && today < dateValue) {
                  return false;
                } else if (field.validation === "FUTURE" && today > dateValue) {
                  return false;
                }
              }
            }
          }
        } else {
          if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.PSD2
          ) {
            if (!field.completed) {
              console.warn("Required PSD2 not synced!");
              return false;
            }
          } else if (
            question.fieldType === FLOW_QUESTION_FIELD_TYPE.STATEMENT_PROMPT &&
            field.type === STATEMENT_PROMPT_TYPE.DYNAMIC
          ) {
            if (!state.applicationData[field.identifier + "_" + question.id]) {
              console.warn(" Required failed!");
              return false;
            }
          } else {
            if (
              !(
                question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
                question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.BARRIER
              ) &&
              !(
                question.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
                question.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.KYC
              ) &&
              !state.applicationData[field.identifier]
            ) {
              console.warn("Required failed!");
              return false;
            }
          }

          if (question.isHardKo) {
            if (state.applicationData[field.identifier] === question.koAnswer) {
              console.warn("KO failed!");
              return false;
            }
          }
          if (
            field &&
            field.type === SIMPLE_FIELD_TYPE.INPUT &&
            field.validation
          ) {
            let value = state.applicationData[field.identifier];

            if (value === undefined) {
              value = "";
            }

            const regex = new RegExp(field.validation);
            if (!regex.test(value)) {
              console.warn("Regex validation failed!");
              return false;
            }
          }
        }
      }

      if (
        !question.isConditional &&
        field &&
        field.type === SIMPLE_FIELD_TYPE.DATE_PICKER &&
        field.validation
      ) {
        let value = state.applicationData[field.identifier];
        if (value && field.validation) {
          let today = new Date();
          let dateValue = new Date(value);
          today.setHours(0, 0, 0, 0);
          dateValue.setHours(0, 0, 0, 0);
          if (field.validation === "PAST" && today < dateValue) {
            return false;
          } else if (field.validation === "FUTURE" && today > dateValue) {
            return false;
          }
        }
      }
    }
    return true;
  },
  getMortgageApplications: (state) => {
    return state.mortgageApplications;
  },
  kycUrl: (state) => {
    return state.kycRecord.url;
  },
  kycStatus: (state) => {
    return state.kycRecord.status ?? "NOT_STARTED";
  },
  kycCompleted: (state) => {
    return state.kycRecord.status === KYC_STATUSES.COMPLETED;
  },
  kycStarted: (state) => {
    return state.kycRecord.status === KYC_STATUSES.STARTED;
  },
  kycWaitingFor4Eyes: (state) => {
    return state.kycRecord.status === KYC_STATUSES.WAITING_FOR_4_EYES;
  },
  baseData: (state) => (loaApplicationId) => {
    return state.baseData[loaApplicationId];
  },
  isBarrierOpen: (state) => (applicationId, questionId) => {
    return state.openedBarriers.some(
      (obj) =>
        obj.applicationId === applicationId && obj.questionId === questionId
    );
  },
  inviteOffer: (state) => {
    return state.inviteOffer;
  },
  isSigningDataReceived: (state) => {
    return state.kycRecord.type === "SIGNATURE";
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
