import { newLogger } from "@/utils/util";
import axios from "axios";
import Vue from "vue";
import jwtDecode from "jwt-decode";

export const JWT_STORAGE_KEY = "fintrous_instacash_af_jwt";
const AUTH_HEADER = "X-Auth-Token";

// Private variables
const endpoints = {
  login: "/api/bulwark/customer/auth/login",
  register: "/api/customer/register",
  completeRegistration: "/api/bulwark/customer/auth/complete-registration",
  completeLogin: "/api/bulwark/customer/auth/complete-login",
  confirmEmail: "/api/bulwark/customer/auth/confirm-email",
  requestPwdChange: "/api/bulwark/customer/auth/request-password-change",
  finishPwdChange: "/api/bulwark/customer/auth/change-password",
  logout: "/api/bulwark/customer/auth/logout",
  externallyCreatedUsersFirstLogin:
    "/api/bulwark/customer/auth/external/first-login",
  resendTwoFactor: "/api/bulwark/customer/auth/resend-two-factor",
  isExternalIdValid: (externalId) =>
    `/api/bulwark/customer/external-id/${externalId}/isValid`,
};
const claims = {
  // grantedAuthorities: "ga",
  refreshToken: "rfsht",
  expiry: "exp",
};
let logger = newLogger("SessionControl");

// Private methods
const parseJwt = (token) => {
  // src: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
  if (!token) return null;
  let jwt = jwtDecode(token);
  // jwt.authorities = jwt[claims.grantedAuthorities].split(",");
  return jwt;
};

/**
 * The session is considered logged in (fully) if the JWT is present and not expired
 */
const isLoggedIn = (jsonJwt) => {
  let now = Date.now();
  return (
    !!jsonJwt && !!jsonJwt[claims.expiry] && now < jsonJwt[claims.expiry] * 1000
  );
};

/**
 * The session is "ready" and considered (roughly) to be loggedIn if the JWT is present and has a refresh-token
 * or if the JWT is not expired
 */
const isReady = (jsonJwt) => {
  return isLoggedIn(jsonJwt) || (!!jsonJwt && !!jsonJwt[claims.refreshToken]);
};

/**
 * Inits the session state from the JWT stored in LocalStorage
 * If the JWT cannot be considered "ready" it is not loaded
 */
const initState = () => {
  let savedJwt = localStorage.getItem(JWT_STORAGE_KEY);
  let jsonJwt = parseJwt(savedJwt);
  let ready = isReady(jsonJwt);
  if (!ready) {
    logger.debug("No usable JWT detected on token: " + savedJwt);
    jsonJwt = {};
    savedJwt = ""; // reset the savedJwt
  } else {
    logger.debug("Usable JWT detected, considering session ready!");
  }
  return {
    encodedJwt: savedJwt,
    jsonJwt: jsonJwt,
    loginRedirect: "/",
    isReady: ready,
    secondFactor: undefined,
    timer: null,
  };
};

const actions = {
  refreshJwt: async (context, jwt) => {
    if (jwt) {
      try {
        let jsonJwt = parseJwt(jwt);
        context.commit("login", { encodedJwt: jwt, jsonJwt: jsonJwt });
      } catch (error) {
        throw "Unable to parse given JWT!";
      }
    } else {
      throw "No JWT in Login response!";
    }
  },
  login: async ({ commit }, loginRequest) => {
    try {
      commit("logout");
      let response = await axios.post(endpoints.login, loginRequest);
      let token = response.headers[AUTH_HEADER.toLowerCase()];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else if (!response.data.secondFactorResponse) {
        throw "No JWT in Login response!";
      }
      return response.data;
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  externallyCreatedUsersFirstLogin: async ({ commit }, loginRequest) => {
    try {
      commit("logout");
      let response = await axios.post(
        endpoints.externallyCreatedUsersFirstLogin,
        loginRequest
      );
      return response.data;
    } catch (e) {
      logger.warn("Error on externally created user's auto-login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  register: async ({ commit }, registration) => {
    try {
      commit("logout");
      let response = await axios.post(endpoints.register, registration);
      return response.data;
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  finishLogin: async ({ commit }, loginRequest) => {
    try {
      commit("logout");
      let response = await axios.post(endpoints.completeLogin, loginRequest);
      let token = response.headers[AUTH_HEADER.toLowerCase()];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else {
        throw "No JWT in Login response!";
      }
      return response.data;
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  confirmEmail: async ({}, request) => {
    try {
      await axios.post(endpoints.confirmEmail, request);
    } catch (e) {
      logger.warn(e);
      return Promise.reject(e);
    }
  },
  resendTwoFactor: async ({ commit }, request) => {
    try {
      commit("logout");
      let response = await axios.post(endpoints.resendTwoFactor, request);
      return response.data;
    } catch (e) {
      logger.warn("Error on resend two factor");
      logger.warn(e);
      return Promise.reject(e);
    }
  },
  finishRegistration: async ({ commit }, registration) => {
    try {
      commit("logout");
      let response = await axios.post(
        endpoints.completeRegistration,
        registration
      );
      let token = response.headers[AUTH_HEADER.toLowerCase()];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Login successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      } else {
        throw "No JWT in Login response!";
      }
      return Promise.resolve(true);
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  requestPwdChange: async ({ commit }, request) => {
    try {
      let response = await axios.post(endpoints.requestPwdChange, request);

      return response.data;
    } catch (e) {
      logger.warn("Error on login");
      logger.warn(e);
      commit("logout");
      return Promise.reject(e);
    }
  },
  isExternalIdValid: async ({}, externalId) => {
    try {
      const response = await axios.get(endpoints.isExternalIdValid(externalId));

      return response.data.valid;
    } catch (e) {
      logger.warn(e);
      return Promise.reject(e);
    }
  },
  finishPwdChange: async ({ commit }, solution) => {
    try {
      let response = await axios.post(endpoints.finishPwdChange, solution);
      let token = response.headers[AUTH_HEADER.toLowerCase()];
      if (token) {
        try {
          let jsonJwt = parseJwt(token);
          commit("login", { encodedJwt: token, jsonJwt: jsonJwt });
          logger.info("Password change successful!");
        } catch (error) {
          throw "Unable to parse given JWT!";
        }
      }
      return response.data;
    } catch (e) {
      console.log(e);
      console.log(e.response);
      commit("logout");
      return Promise.reject(e);
    }
  },
  logout: (context) => {
    context.commit("logout");
  },
  saveSecondFactor: (context, data) => {
    context.commit("secondFactor", data);
  },
  deleteSecondFactor: (context) => {
    context.commit("secondFactor", null);
  },
  stepTimer: ({ commit }, time) => {
    commit("modifyTimer", time);
  },
};

const mutations = {
  login: (state, { encodedJwt, jsonJwt }) => {
    const newIat = parseJwt(encodedJwt)?.iat;
    const actualIat = parseJwt(localStorage.getItem(JWT_STORAGE_KEY))?.iat;
    if (newIat && actualIat && newIat < actualIat) {
      // if arrives old jwt from disk cache
      return;
    }

    Vue.set(state, "encodedJwt", encodedJwt);
    Vue.set(state, "jsonJwt", jsonJwt);
    Vue.set(state, "isReady", true);
    localStorage.setItem(JWT_STORAGE_KEY, encodedJwt);
  },
  logout: (state) => {
    Vue.set(state, "encodedJwt", "");
    Vue.set(state, "jsonJwt", {});
    Vue.set(state, "isReady", false);
    localStorage.setItem(JWT_STORAGE_KEY, "");
  },
  redirect: (state, url) => {
    state.loginRedirect = url;
  },
  secondFactor: (state, secondFactor) => {
    state.secondFactor = secondFactor;
  },
  stepTimer: (state, time) => {
    state.timer = time;
  },
};

const getters = {
  axios: (state) => {
    return state.axios;
  },
  isLoggedIn: (state) => {
    return isLoggedIn(state.jsonJwt);
  },
  isReady: (state) => {
    return state.isReady;
  },
  jwt: (state) => {
    return state.encodedJwt;
  },
  userId: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.sub;
    } else {
      return "UNKNOWN";
    }
  },
  displayName: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.fname;
    } else {
      return "ISMERETLEN";
    }
  },
  email: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.email;
    } else {
      return "ISM@ERETL.EN";
    }
  },
  partnerId: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.ptnid;
    } else {
      return 0;
    }
  },
  phone: (state) => {
    if (isReady(state.jsonJwt)) {
      return state.jsonJwt.phone;
    } else {
      return "+36ISMERETLEN";
    }
  },
  secondFactor: (state) => {
    return state.secondFactor;
  },
  timer: (state) => {
    return state.timer;
  },
  // hasAuthority: (state) => (auth) => {
  //   if (!auth) return true;
  //   let hasAuthority = false;
  //   if (state.jsonJwt.authorities) {
  //     hasAuthority = state.jsonJwt.authorities.includes(auth);
  //   }
  //   return hasAuthority;
  // },
  // hasAnyAuthority: (state) => (...auths) => {
  //   let hasAuthority = false;
  //   if (state.jsonJwt.authorities) {
  //     hasAuthority = state.jsonJwt.authorities.some((role) =>
  //       auths.includes(role)
  //     );
  //   }
  //   return hasAuthority;
  // },
  jwtHeader: (state) => {
    return { [AUTH_HEADER]: state.encodedJwt };
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
