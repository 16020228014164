import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import Buefy from "buefy";
import VueMask from "v-mask";
import VeeValidate from "./plugins/vee-validate";
import FontAwesome from "./plugins/font-awesome";
import PortalVue from "portal-vue";
import VueObserveVisibility from "vue-observe-visibility";
import titleMixin from "./mixins/titleMixin";
import * as Sentry from "@sentry/vue";

import VueGtag from "vue-gtag";

import "@/assets/scss/main.scss";
import { monify } from "./utils/util";

Vue.prototype.gapi = window.gapi;
Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: "G-HQV0DCXL66" },
    appName: "OLM AF",
    pageTrackerScreenviewEnabled: true,
  },
  router
);

Vue.use(VueObserveVisibility);
Vue.mixin(titleMixin);

Vue.filter("monify", function (value) {
  return monify(value);
});

Vue.use(Buefy, {
  defaultToastPosition: "is-bottom",
  defaultSnackbarPosition: "is-bottom",
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fa",
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x",
      },
      iconPrefix: "",
    },
  },
});

Vue.use(VueMask);
Vue.use(VeeValidate);
Vue.use(FontAwesome);
Vue.use(PortalVue);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  debug: false,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({ levels: ["error"] }),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: [
    "localhost",
    /^https:\/\/ohp-uat\.mbhbank\.hu\/api/,
  ],

  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
