import { newLogger } from "@/utils/util";
import network from "../../../utils/network";
let logger = newLogger("TripleOfferConfigStore");

const urls = {
  tripleOfferConfig: "/administration/triple-offer-config",
};

/**
 * Administration Triple Offer Config store
 */

const initState = () => {
  return {
    data: {},
  };
};

const actions = {
  fetch: async ({ commit }) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.tripleOfferConfig);

      commit("saveOne", data);

      return Promise.resolve(data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveOne: (state, data) => {
    state.data = data;
  },
};

const getters = {
  get: (state) => {
    return state.data;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
