<template>
  <div
    v-show="loaded"
    id="app"
    :class="autoloadConfigValue(UI_CONFIGS.APPEARANCE)"
  >
    <AppLayout>
      <router-view />
    </AppLayout>

    <b-modal v-model="showInactivityModal" :can-cancel="false">
      <InactivityModal />
    </b-modal>

    <b-modal v-model="showApplicationInProgressModal" :can-cancel="false">
      <ApplicationInProgressModal @accept="acceptModal" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import network from "./utils/network";
import { checkInactivity, newLogger } from "@/utils/util";
import { CUSTOM_APPEARANCE, UI_CONFIGS } from "@/utils/const";
import InactivityModal from "@/components/InactivityModal.vue";
import {
  getActiveLandingParams,
  removeActiveLandingParam,
  saveLandingParams,
} from "@/utils/landingParamUtil";
import ApplicationInProgressModal from "@/components/ApplicationInProgressModal.vue";

const logger = newLogger("App.VUE");
const TODO_POLL_SECOND = 60000;

export default {
  title: "af:page.default.title",
  components: {
    ApplicationInProgressModal,
    InactivityModal,
    AppLayout: () => import("@/layouts/AppLayout.vue"),
  },
  watch: {
    "$route.meta.protected": {
      immediate: true,
      handler: function () {
        this.protectedRoute = this.$route.meta.protected;
        if (this.loaded) {
          if (this.protectedRoute) {
            this.startInactivityCheck();
          }
        }
      },
    },
  },
  data() {
    return {
      todoCountPoll: null,
      loaded: false,
      protectedRoute: false,
      timer: null,
      showInactivityModal: false,
      showApplicationInProgressModal: false,
    };
  },
  async created() {
    network.configure(this.$store, this.$router);

    await this.fetchPublicTokens();
    await this.fetchInternalTokens();
    await this.fetchAutoloadConfigs();

    if (!getActiveLandingParams()) {
      saveLandingParams({
        amount: null,
        term: null,
      });
    }

    if (this.protectedRoute) {
      if (this.mortgageExternalId) {
        await this.$store.dispatch("profile/fetch");
        if (this.$store.getters["profile/hasActiveMortgageApplication"]) {
          this.showApplicationInProgressModal = true;
        } else {
          await this.$store.dispatch(
            "mortgagePreliminaryAssessment/connectExternal",
            this.mortgageExternalId
          );
          await this.doRedirect("MortgagePreliminaryAssessment");
        }
      }
      this.startInactivityCheck();
    }

    const gtmId = this.autoloadConfigValue(UI_CONFIGS.GTM_ID);

    if (gtmId && gtmId.length > 0) {
      let gtmScript = document.createElement("script");
      gtmScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;
      document.head.appendChild(gtmScript);

      this.$gtag.config({ id: gtmId });
    }

    if (
      this.autoloadConfigValue(UI_CONFIGS.APPEARANCE) === CUSTOM_APPEARANCE.MBH
    ) {
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = "/favicon_mbh.ico";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    this.loaded = true;

    await this.fetchHasActivePurchase();
    await this.fetchTodoCount();
    await this.fetchImage();

    this.todoCountPoll = window.setInterval(() => {
      this.fetchTodoCount();
    }, TODO_POLL_SECOND);
  },
  beforeDestroy() {
    window.clearInterval(this.todoCountPoll);
  },
  computed: {
    UI_CONFIGS: () => UI_CONFIGS,
    ...mapGetters({
      loggedIn: "session/isReady",
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    mortgageExternalId() {
      try {
        const landingParams = getActiveLandingParams();
        if (!landingParams) {
          return null;
        }
        const parsedParams = JSON.parse(landingParams);
        return parsedParams.mortgageExternalId || null;
      } catch (error) {
        console.error("Error parsing landing parameters:", error);
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchPublicTokensByLanguage: "i18n/fetchTokensByLanguage",
      fetchInternalTokensByLanguage: "i18n/fetchInternalTokensByLanguage",
      loadTokensByLanguage: "i18n/fetchInternalTokensByLanguage",
      fetchImage: "image/fetchImage",
      fetchAutoloadConfigsFromStore: "config/fetchAutoloadConfigs",
    }),
    async acceptModal() {
      this.showApplicationInProgressModal = false;
      await this.doRedirect("UserMortgageApplications");
    },
    async doRedirect(redirect) {
      removeActiveLandingParam("mortgageExternalId");
      await this.$router.push({ name: redirect });
    },
    async fetchPublicTokens() {
      try {
        await this.fetchPublicTokensByLanguage("hu");
      } catch (ex) {
        logger.error(ex);
        logger.error("Public Token fetch failed!");
        setTimeout(() => this.fetchPublicTokens(), 1000);
      }
    },
    async fetchTodoCount() {
      if (this.loggedIn) {
        await this.$store.dispatch("myTodo/fetchCount");
        await this.$store.dispatch("myTodo/fetchMortgageCount");
      }
    },
    async fetchHasActivePurchase() {
      if (this.loggedIn) {
        await this.$store.dispatch("purchase/hasActivePurchase");
      }
    },
    async fetchInternalTokens() {
      if (this.$store.getters["session/isReady"]) {
        try {
          await this.fetchInternalTokensByLanguage("hu");
        } catch (ex) {
          logger.error(ex);
          logger.error("Internal Token fetch failed!");
          this.retryInternalTokens();
        }
      } else {
        this.retryInternalTokens();
      }
    },
    retryInternalTokens() {
      setTimeout(() => this.fetchInternalTokens(), 1000);
    },
    async fetchAutoloadConfigs() {
      try {
        await this.fetchAutoloadConfigsFromStore();
      } catch (ex) {
        logger.error(ex);
        logger.error("Autoload config fetch failed!");
        setTimeout(() => this.fetchAutoloadConfigs(), 1000);
      }
    },
    logout() {
      this.$store.dispatch("session/logout");
      this.$store.dispatch("preliminaryAssessment/drop");
      this.$router.push({
        name: "WelcomeLogin",
      });
    },
    startInactivityCheck() {
      if (
        this.autoloadConfigValue(UI_CONFIGS.INACTIVITY_FEATURE) === "true" &&
        this.protectedRoute
      ) {
        checkInactivity(
          parseInt(this.autoloadConfigValue(UI_CONFIGS.INACTIVITY_MODAL_TIME)),
          parseInt(this.autoloadConfigValue(UI_CONFIGS.INACTIVITY_LOGOUT_TIME)),
          () => {
            if (this.protectedRoute) {
              this.showInactivityModal = true;
            }
          },
          () => {
            this.showInactivityModal = false;
            this.$store.commit("session/stepTimer", null);
            this.logout();
          },
          (passed, timer) => {
            if (!this.protectedRoute) {
              clearInterval(timer);
            }
            this.$store.commit(
              "session/stepTimer",

              parseInt(
                this.autoloadConfigValue(UI_CONFIGS.INACTIVITY_LOGOUT_TIME)
              ) - passed
            );
          },
          () => {
            this.showInactivityModal = false;
            this.$store.commit(
              "session/stepTimer",
              parseInt(
                this.autoloadConfigValue(UI_CONFIGS.INACTIVITY_LOGOUT_TIME)
              )
            );
          }
        );
      }
    },
  },
  mounted() {
    if (this.autoloadConfigValue(UI_CONFIGS.OWN_COOKIE_SOLUTION) === "true") {
      let cookiePreference =
        localStorage.getItem("allowAnalyticsCookies") || "false";
      if (cookiePreference === "true") {
        this.$gtag.query("consent", "default", {
          analytics_storage: "granted",
        });
      } else {
        this.$gtag.query("consent", "default", {
          analytics_storage: "denied",
        });
      }
    }
  },
  // updated() {
  //   let cookiePreference =
  //     localStorage.getItem("allowAnalyticsCookies") || "false";
  //   if (cookiePreference === "true") {
  //     this.$gtag.query("consent", "update", {
  //       analytics_storage: "granted",
  //     });
  //   } else {
  //     this.$gtag.query("consent", "update", {
  //       analytics_storage: "denied",
  //     });
  //   }
  // },
};
</script>
