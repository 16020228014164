import network from "../../../utils/network";
import Vue from "vue";

const urls = {
  start: `/credit/psd2-sync/start`,
  continue: (id) => `/credit/psd2-sync/continue/${id}`,
  failed: (id) => `/credit/psd2-sync/failed/${id}`,
  manualSalary: `/credit/psd2-sync/manual-salary`,
  manualLoanAmount: `/credit/psd2-sync/manual-loan-amount`,
  mistakeFound: `/credit/psd2-sync/mistake-found`,
  findLatest: (query) => `/credit/psd2-sync/customer?${query}`,
};

const initState = () => {
  return {
    latest: null,
    syncEntity: {},
    fieldRefs: {},
  };
};

const actions = {
  start: async ({}, request) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.post(urls.start, request);
      return data;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  continue: async ({}, syncId) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.post(urls.continue(syncId));
      return data;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  setFailed: async ({}, syncId) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.patch(urls.failed(syncId));
      return data;
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  findLatest: async (
    { commit },
    { source, fieldId, correctionId, loanApplicationId }
  ) => {
    try {
      const axios = await network.connection();
      let querystring = new URLSearchParams();
      querystring.set("source", source);
      querystring.set("fieldId", fieldId);
      querystring.set("correctionId", correctionId);
      querystring.set("loanApplicationId", loanApplicationId);
      let { data } = await axios.get(urls.findLatest(querystring.toString()));
      if (data) {
        commit("saveLatest", data);
      } else {
        commit("saveLatest", null);
      }
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  updateManualSalary: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.patch(urls.manualSalary, request);
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  updateManualLoanAmount: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.patch(urls.manualLoanAmount, request);
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  mistakeFound: async ({}, request) => {
    try {
      const axios = await network.connection();
      await axios.post(urls.mistakeFound, request);
    } catch (err) {
      // logger.error(err);
      await Promise.reject(err);
    }
  },
  // Don't try this at home...
  saveFieldRef: async ({ commit }, field) => {
    commit("saveFieldRef", field);
  },
  deleteFieldRef: async ({ commit }, field) => {
    commit("deleteFieldRef", field);
  },
};

const mutations = {
  saveLatest: (state, data) => {
    Vue.set(state, "latest", data);
  },
  saveFieldRef: (state, field) => {
    Vue.set(state.fieldRefs, field._uid, field);
  },
  deleteFieldRef: (state, field) => {
    Vue.delete(state.fieldRefs, field._uid);
  },
  saveSyncEntity: (state, syncEntity) => {
    state.syncEntity = syncEntity;
  },
};

const getters = {
  getLatest: (state) => {
    return state.latest;
  },
  syncEntity: (state) => {
    return state.syncEntity;
  },
  getFieldRefs: (state) => {
    return state.fieldRefs;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
