import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "../../../utils/network";

let logger = newLogger("AdministrationReadonlyFieldStore");

const urls = {
  readonlyFieldWithId: (id) => `/administration/readonly-field/${id}`,
};

/**
 * Administration ReadonlyField store
 */

const initState = () => {
  return {
    readonlyFieldData: {},
  };
};

const actions = {
  fetchOne: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.readonlyFieldWithId(id));
      commit("saveOne", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  saveOne: (state, data) => {
    Vue.set(state.readonlyFieldData, data.id, data);
  },
};

const getters = {
  get: (state) => (id) => {
    return state.readonlyFieldData[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
