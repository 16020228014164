<template>
  <div class="application-in-progress-modal">
    <div class="application-in-progress-modal__body">
      <div class="application-in-progress-modal__text">
        <p class="is-size-4 has-text-weight-bold">
          {{ $t("af:application_in_progress_modal.title") }}
        </p>

        <div class="mb-6">
          <div class="usp is-flex is-align-items-center my-2">
            <span class="has-text-grey-icon">{{
              $t("af:application_in_progress_modal.subtitle")
            }}</span>
          </div>
        </div>

        <b-button
          type="is-green"
          class="has-text-weight-medium"
          expanded
          @click="cancelModal"
          >{{ $t("af:application_in_progress_modal.ok") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { removeActiveLandingParam } from "@/utils/landingParamUtil";

export default {
  name: "ApplicationInProgressModal",
  methods: {
    cancelModal() {
      console.log("hello");
      removeActiveLandingParam("externalId");
      this.$emit("accept");
    },
  },
  emits: ["accept"],
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.application-in-progress-modal {
  width: 600px;
  max-width: 80vw;
  background: white;
  border-radius: 24px;
  position: relative;
  margin: auto;

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__text {
    width: 100%;
    padding: 32px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 769px) {
  .application-in-progress-modal {
    &__body {
      display: flex;
      flex-direction: row;
    }

    &__text {
      padding: 48px !important;
    }
  }
}
</style>
