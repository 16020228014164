import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { size, email, regex, required } from "vee-validate/dist/rules";
import i18n from "./i18n";

const VeeValidate = {
  install(Vue) {
    extend("required", {
      message: () => i18n.t("af:form.validation.message.required"),
      ...required,
    });

    extend("statementRequired", {
      message: () => i18n.t("af:form.validation.message.statement_required"),
      validate: (value) => {
        return !(
          value === undefined ||
          value === null ||
          value === "" ||
          value === false
        );
      },
    });

    extend("statementMustBeAccepted", {
      message: () =>
        i18n.t("af:form.validation.message.statement_must_be_accepted"),
      validate: (value) => {
        return !(value === false);
      },
    });

    extend("size", {
      ...size,
      message: () => i18n.t("af:form.validation.message.size"),
    });

    extend("email", {
      ...email,
      message: () => i18n.t("af:form.validation.message.email"),
    });

    extend("regex", {
      ...regex,
      message: () => i18n.t("af:form.validation.message.regex"),
    });

    extend("past", {
      message: () => i18n.t("af:form.validation.message.past"),
      validate: (value) => {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        value.setHours(0, 0, 0, 0);
        return today >= value;
      },
    });

    extend("validDate", {
      message: () => i18n.t("af:form.validation.message.required"),
      validate: (value) => {
        return value instanceof Date;
      },
    });

    extend("future", {
      message: () => i18n.t("af:form.validation.message.future"),
      validate: (value) => {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        value.setHours(0, 0, 0, 0);
        return today <= value;
      },
    });

    extend("phone", {
      message: () => i18n.t("af:form.validation.message.phone"),
      validate: (value) => {
        const regex = new RegExp(
          "[+36 ][0-9][0-9][ ][0-9][0-9][0-9][ ][0-9][0-9][0-9][0-9]"
        );

        return regex.test(value);
      },
    });

    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
  },
};

export default VeeValidate;
