const LANDING_PARAM_KEY = "fintrous_instacash_landing_params";
const LANDING_PARAM_TIMESTAMP_KEY =
  "fintrous_instacash_landing_params_timestamp";
const EXPIRATION_TIME = 86400000; // 1 day in ms

export function validateLandingParams(params) {
  if (params && Object.keys(params).length > 0) {
    if (params.amount || params.term || params.thm) {
      if (!parseInt(params.amount)) {
        return false;
      }
      if (!parseInt(params.term)) {
        return false;
      }
      if (!/^[0-9,]*$/.test(params.thm)) {
        return false;
      }
    }
    return true;
  }
}

export function saveLandingParams(params) {
  if (params && Object.keys(params).length > 0) {
    if (!validateLandingParams(params)) {
      return;
    }

    let paramsJson = JSON.stringify(params);
    console.debug("Saving landing params: " + paramsJson);

    clearLandingParams();
    localStorage.setItem(LANDING_PARAM_KEY, paramsJson);
    localStorage.setItem(
      LANDING_PARAM_TIMESTAMP_KEY,
      new Date().getTime().toString()
    );
  }
}

export function editLandingParam(key, value) {
  const landingParams = JSON.parse(localStorage[LANDING_PARAM_KEY]);
  landingParams[key] = value;
  localStorage.setItem(LANDING_PARAM_KEY, JSON.stringify(landingParams));
}

export function getActiveLandingParams() {
  let params = localStorage[LANDING_PARAM_KEY];
  if (params) {
    let timestamp = new Date(
      parseInt(localStorage.getItem(LANDING_PARAM_TIMESTAMP_KEY), 10)
    );

    let expirationDate = new Date(timestamp.getTime() + EXPIRATION_TIME);
    if (new Date() > expirationDate) {
      console.debug("Expired landing params!");
      clearLandingParams();
      return null;
    }

    return localStorage[LANDING_PARAM_KEY];
  }
  return null;
}

export function removeActiveLandingParam(paramKey) {
  try {
    let params = JSON.parse(localStorage[LANDING_PARAM_KEY]);
    delete params[paramKey];
    localStorage.setItem(LANDING_PARAM_KEY, JSON.stringify(params));
  } catch {
    console.log("There was no landing param objects stored");
  }
}

export function clearLandingParams() {
  localStorage.removeItem(LANDING_PARAM_KEY);
  localStorage.removeItem(LANDING_PARAM_TIMESTAMP_KEY);
}
