import Vue from "vue";
import { newLogger } from "../../../utils/util";
import network from "../../../utils/network";

let logger = newLogger("OnboardingStore");

const urls = {
  onboarding: (type) => `/administration/onboarding?type=${type}`,
};

/**
 * Onboarding Page Configuration store
 */

const initState = () => {
  return {
    onboardingPages: [],
  };
};

const actions = {
  fetchOnboarding: async ({ commit }, type) => {
    try {
      let axios = await network.connection();
      let { data } = await axios.get(urls.onboarding(type));
      commit("save", data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  save: (state, configurations) => {
    Vue.set(state, "onboardingPages", configurations);
  },
};

const getters = {
  onboardingPages: (state) => {
    return state.onboardingPages;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
