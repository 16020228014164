import Vue from "vue";
import { newLogger } from "@/utils/util";
import network from "@/utils/network";

let logger = newLogger("MyTodoStore");

const urls = {
  correctionWithId: (id) =>
    `/credit/loan-application-correction/customer/${id}`,
  complete: (id) => `/credit/loan-application-correction/complete/${id}`,
  mortgageCorrectionWithId: (id) =>
    `/mortgage/mortgage-application-correction/customer/${id}`,
  mortgageComplete: (id) =>
    `/mortgage/mortgage-application-correction/complete/${id}`,
};

const initState = () => {
  return {
    correction: {},
    mortgageCorrection: {},
  };
};

const actions = {
  fetch: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.correctionWithId(id));
      commit("save", {
        id,
        data,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  complete: async ({}, { id, data }) => {
    try {
      const axios = await network.connection();
      await axios.post(urls.complete(id), data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  fetchMortgage: async ({ commit }, id) => {
    try {
      const axios = await network.connection();
      let { data } = await axios.get(urls.mortgageCorrectionWithId(id));
      commit("saveMortgage", {
        id,
        data,
      });
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
  completeMortgage: async ({}, { id, data }) => {
    try {
      const axios = await network.connection();
      await axios.post(urls.mortgageComplete(id), data);
    } catch (err) {
      logger.error(err);
      await Promise.reject(err);
    }
  },
};

const mutations = {
  save: (state, { id, data }) => {
    Vue.set(state.correction, id, data);
  },
  saveMortgage: (state, { id, data }) => {
    Vue.set(state.mortgageCorrection, id, data);
  },
};

const getters = {
  get: (state) => (id) => {
    return state.correction[id];
  },
  getMortgage: (state) => (id) => {
    return state.mortgageCorrection[id];
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations,
  actions,
  getters,
};
