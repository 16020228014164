import axios from "axios";
import { newLogger } from "./util";
import { JWT_STORAGE_KEY } from "@/store/modules/session";

const AUTH_HEADER = "X-Auth-Token";

let logger = newLogger("Network");

let delay = (t) => {
  return new Promise((resolve) => {
    setTimeout(resolve, t);
  });
};

class Network {
  constructor() {
    this.router = null;
    this.store = null;
    this.http = null;
  }

  /**
   * Called from app.vue on creation, sets the store reference and creates the axios instance
   */
  configure(store, router) {
    this.router = router;
    this.store = store;
    this.http = this.createAxiosInstance();
  }

  getJwt() {
    return localStorage.getItem(JWT_STORAGE_KEY);
  }

  createAxiosInstance() {
    const http = axios.create({
      baseURL: "/api",
      timeout: 60000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // responseType: 'json',
    });
    http.interceptors.request.use(
      (config) => {
        let jwt = this.getJwt();
        if (jwt == null) {
          this.store.dispatch("session/logout");
          this.router.push("/login");
        }
        config.headers[AUTH_HEADER] = jwt;
        logger.debug("Added JWT to outgoing call.");
        return config;
      },
      (error) => {
        logger.error("AJAX call wasn't even sent.");
        logger.error(error);
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        let jwt = response.headers[AUTH_HEADER.toLowerCase()];
        if (jwt) {
          logger.debug("Refreshing JWT...");
          this.store.dispatch("session/refreshJwt", jwt);
        } else {
          logger.debug("No JWT in response!");
        }
        return Promise.resolve(response);
      },
      (error) => {
        logger.warn("AJAX call resulted in an error response.");
        logger.warn(error);
        if (error.response.status === 401) {
          // unauthorized
          this.store.dispatch("session/logout");
          this.router.push("/login");
        }
        if (error.response.status === 403) {
          // forbidden
          this.router.push("/");
        }
        return Promise.reject(error);
      }
    );
    return http;
  }

  async connection() {
    if (this.store.getters["session/isReady"]) return this.http;
    console.log("No JWT detected, establishing an interval to wait for it...");
    let rounds = 0;
    while (rounds < 6 && !this.store.getters["session/isReady"]) {
      console.log("Waiting for session establishment...");
      await delay(500);
      rounds++;
    }
    return this.http;
  }
}

// singleton
export default new Network();
